import * as CryptoJS from 'crypto-js';

export const decrypt = (str: string) => {
  const decrypted = CryptoJS.AES.decrypt(str, 'q');
  return decrypted.toString(CryptoJS.enc.Utf8);
};

export const encrypt = (obj: any) => {
  const txt = JSON.stringify(obj);
  const digest = CryptoJS.AES.encrypt(txt, 'q');
  return digest.toString();
};

const parse = (str: string): any => {
  try {
    return JSON.parse(str);
  } catch (err: any) {
    return {};
  }
};
