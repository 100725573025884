import { useState, useCallback } from 'react';
import { AvailableCountriesQuery, OrderAddress, useAvailableCountriesQuery } from '~/generated/graphql';
import { z } from 'zod';
import { useForm, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Input, Select, HighlightedButton, Button } from '~/components/common';
import { PencilIcon, CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';

export const addressScheme = z.object({
  fullName: z.string().min(1, { message: 'Name is required' }),
  city: z.string(),
  countryCode: z.string().min(1, { message: 'Country is required' }),
  postalCode: z.string(),
  province: z.string(),
  streetLine1: z.string().min(1, { message: 'Address is required' }),
  streetLine2: z.string(),
  phoneNumber: z.string(),
  company: z.string(),
});

type AddressType = z.infer<typeof addressScheme>;

export function AddressForm({
  address,
  defaultFullName,
  onSave,
  isEditable,
  isLoading,
}: {
  address?: OrderAddress | null;
  defaultFullName?: string;
  onSave?: (data: AddressType) => void;
  isEditable?: boolean;
  isLoading?: boolean;
}) {
  const [isEditing, setIsEditing] = useState(isEditable === true ? false : true);
  const availableCountriesHook = useAvailableCountriesQuery();
  const availableCountries = availableCountriesHook.data?.availableCountries || [];
  const formHook = useForm<AddressType>({
    resolver: zodResolver(addressScheme),
    defaultValues: {
      fullName: address?.fullName || undefined,
      city: address?.city || undefined,
      streetLine1: address?.streetLine1 || undefined,
      streetLine2: address?.streetLine2 || undefined,
      countryCode: (address?.countryCode || address?.countryCode) ?? 'US',
      postalCode: address?.postalCode || undefined,
      phoneNumber: address?.phoneNumber || undefined,
      company: address?.company || undefined,
      province: address?.province || undefined,
    },
  });
  const { handleSubmit } = formHook;

  const onSubmit: SubmitHandler<AddressType> = useCallback(
    async (data) => {
      console.log('data', data);
      if (onSave) {
        onSave(data);
      }
      if (isEditable) {
        setIsEditing(false);
      }
    },
    [address],
  );

  const startEditing = (e: any) => {
    e.preventDefault();
    setIsEditing(true);
  };

  const stopEditing = (e: any) => {
    e.preventDefault();
    setIsEditing(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4'>
      <div className='sm:col-span-2'>
        {isEditing && <Input label='Full name' name='fullName' required autoComplete='full-name' formHook={formHook} />}
        {!isEditing && (
          <>
            <label htmlFor='fullName' className='block text-sm font-medium text-gray-700'>
              Full name
            </label>
            <div className='mt-1'>{defaultFullName}</div>
          </>
        )}
      </div>

      <div className='sm:col-span-2'>
        {isEditing && <Input label='Company' name='company' formHook={formHook} />}
        {!isEditing && (
          <>
            {' '}
            <label htmlFor='company' className='block text-sm font-medium text-gray-700'>
              Company
            </label>
            <div className='mt-1'>{address?.company ?? ''}</div>
          </>
        )}
      </div>

      <div className='sm:col-span-2'>
        {isEditing && <Input label='Address' name='streetLine1' required autoComplete='address-line1' formHook={formHook} />}
        {!isEditing && (
          <>
            {' '}
            <label htmlFor='streetLine1' className='block text-sm font-medium text-gray-700'>
              Address
            </label>
            <div className='mt-1'>{address?.streetLine1 ?? ''}</div>
          </>
        )}
      </div>

      <div className='sm:col-span-2'>
        {isEditing && (
          <Input label='Apartment, suite, etc.' name='streetLine2' autoComplete='address-line2' formHook={formHook} />
        )}
        {!isEditing && (
          <>
            {' '}
            <label htmlFor='streetLine2' className='block text-sm font-medium text-gray-700'>
              Apartment, suite, etc.
            </label>
            <div className='mt-1'>{address?.streetLine2 ?? ''}</div>
          </>
        )}
      </div>

      <div>
        {isEditing && <Input label='City' name='city' required autoComplete='locality' formHook={formHook} />}
        {!isEditing && (
          <>
            <label htmlFor='city' className='block text-sm font-medium text-gray-700'>
              City
            </label>
            <div className='mt-1'>{address?.city ?? ''}</div>
          </>
        )}
      </div>

      <div>
        {isEditing && (
          <Select
            name='countryCode'
            autoComplete='country'
            placeholder='Select a country...'
            required
            label='Country'
            formHook={formHook}
          >
            {availableCountries?.map((country) => (
              <option key={country.id} value={country.code}>
                {country.name}
              </option>
            ))}
          </Select>
        )}
        {!isEditing && (
          <>
            {' '}
            <label htmlFor='countryCode' className='block text-sm font-medium text-gray-700'>
              Country
            </label>
            <div className='mt-1'>{address?.countryCode ?? 'US'}</div>
          </>
        )}
      </div>

      <div>
        {isEditing && <Input label='Province / State' name='province' autoComplete='address-level1' formHook={formHook} />}

        {!isEditing && (
          <>
            <label htmlFor='province' className='block text-sm font-medium text-gray-700'>
              State / Province
            </label>
            <div className='mt-1'>{address?.province ?? ''}</div>
          </>
        )}
      </div>

      <div>
        {isEditing && <Input label='Postal code' name='postalCode' required autoComplete='postal-code' formHook={formHook} />}

        {!isEditing && (
          <>
            <label htmlFor='postalCode' className='block text-sm font-medium text-gray-700'>
              Postal code
            </label>
            <div className='mt-1'>{address?.postalCode ?? ''}</div>
          </>
        )}
      </div>

      <div className='sm:col-span-2'>
        {isEditing && <Input label='Phone' name='phoneNumber' autoComplete='phone' formHook={formHook} />}

        {!isEditing && (
          <>
            <label htmlFor='phoneNumber' className='block text-sm font-medium text-gray-700'>
              Phone
            </label>
            <div className='mt-1'>{address?.phoneNumber ?? ''}</div>
          </>
        )}
      </div>
      {isEditing ? (
        <div className='flex gap-x-4'>
          <HighlightedButton type='submit' isSubmitting={isLoading}>
            <CheckIcon className='w-4 h-4' /> Save
          </HighlightedButton>

          {isEditable && (
            <Button onClick={stopEditing}>
              <XMarkIcon className='w-4 h-4' /> Cancel
            </Button>
          )}
        </div>
      ) : (
        <HighlightedButton onClick={startEditing} className='flex gap-x-4'>
          <PencilIcon className='w-4 h-4' /> Edit
        </HighlightedButton>
      )}
    </form>
  );
}
