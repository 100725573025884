import { AnyAction } from '@reduxjs/toolkit';
import { put, takeLatest, select, call } from 'redux-saga/effects';
import { appActions, AppLocale, activeRouteSelector, localeSelector } from '~/store/app/reducer';
import i18next from '~/i18n';
import { Logger } from '~/utils/helper';

const logger = Logger('AppSaga');

export function* initTheApp() {
  try {
    const locale: AppLocale = yield select(localeSelector);
    yield put(appActions.switchLanguage(locale));
    // yield put(cartActions.fetchColors());
    // yield put(cartActions.fetchSizes());
    // yield delay(5000);
    // logger('moj init');
    // yield call(authorize);
  } finally {
    yield put(appActions.setAppReady(true));
    // yield delay(5000);
    // yield put(appActions.switchLanguage(AppLocale.AR));
  }
}

export function* toggleActiveRoute(action: AnyAction) {
  try {
    const newRoute = action.payload;
    const activeRoute: string = yield select(activeRouteSelector);
    // if (activeRoute === PAGES.searchResult.name) {
    //   const url = new URL(window.location as any);
    //   const q = url.searchParams.get('q');
    //   logger('q', q);
    //   if (q && q.length > 0) {
    //     logger('component init actions');
    //     yield put(searchActions.decodeSearch(q));
    //   } else {
    //     // yield put(searchActions.resetSearchUrl());
    //   }
    // } else if (newRoute === PAGES.searchResult.name) {
    //   yield put(searchActions.runSearch());
    // }
    yield put(appActions.updateActiveRoute(newRoute));
    logger(`route from ${activeRoute} to ${newRoute}`);
  } catch (e) {
    logger('Error: ', e);
  }
}

export default [
  takeLatest(appActions.initializeTheApp, initTheApp),
  // takeLatest(appActions.switchLanguage, switchLanguage),
  // takeLatest(appActions.authorize, authorize),
  // takeLatest(appActions.loginByPassword, loginByPassword),
  // takeLatest(appActions.logout, logout),
  // takeLatest(appActions.resetPassword, resetPassword),
  takeLatest(appActions.toggleActiveRoute, toggleActiveRoute),
];
