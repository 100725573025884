import { useEffect } from 'react';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { useSearchParams } from 'react-router-dom';
import { PAGES } from '~/constants/pages';
import { navigate } from '~/store/navigator';
import { useLogin } from '~/hooks/useLogin';

export default function VerifyTokenPage() {
  const [searchParams] = useSearchParams();
  const { verify, verifyError, verifySuccess } = useLogin();

  const toRedirectOrHome = () => navigate(searchParams.get('redirectTo') || PAGES.home.href);

  useEffect(() => {
    verify(searchParams.get('token') || '');
  }, []);

  useEffect(() => {
    if (!verifySuccess) {
      return;
    }
    setTimeout(() => toRedirectOrHome(), 5000);
  }, [verifySuccess]);

  return (
    <div className='flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
      <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
        <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
          {verifySuccess ? (
            <div className='rounded-md bg-green-100 p-4'>
              <div className='flex items-center'>
                <div className='flex-shrink-0'>
                  <CheckCircleIcon className='h-5 w-5 text-green-600' aria-hidden='true' />
                </div>
                <div className='ml-3'>
                  <p className='text-sm text-green-700'>Your account has been verified successfully. Redirecting in 5s...</p>
                </div>
                <form>
                  <button onClick={toRedirectOrHome} style={{ display: 'none ' }} />
                </form>
              </div>
            </div>
          ) : (
            <div className='rounded-md bg-red-50 p-4'>
              <div className='flex'>
                <div className='flex-shrink-0'>
                  <XCircleIcon className='h-5 w-5 text-red-400' aria-hidden='true' />
                </div>
                <div className='ml-3'>
                  <p className='text-sm text-red-700'>{verifyError}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
