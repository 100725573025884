import AddAddressCard from '~/components/account/AddressCard';
import EditAddressCard from '~/components/account/EditAddressCard';
import { Address, ErrorCode, ErrorResult, useActiveCustomerAddressesQuery } from '~/generated/graphql';

export function AccountAddresses() {
  // const { activeCustomerAddresses } = useLoaderData<typeof loader>();
  const { data } = useActiveCustomerAddressesQuery({ fetchPolicy: 'network-only' });
  const activeCustomerAddresses = data?.activeCustomer;

  return (
    <>
      <div className='w-full'>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 flex-1 mt-4'>
          <AddAddressCard />
          {activeCustomerAddresses?.addresses!.map((address) => {
            return <EditAddressCard address={address as Address} key={address.id} />;
          })}
        </div>
      </div>
    </>
  );
}
