/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { classNames } from '~/utils/class-names';
import { useLocation } from 'react-router-dom';
import CheckoutShipping from './CheckoutShipping';
import { PAGES } from '~/constants/pages';
import CheckoutPayment from './Paymnet';
import { Steps } from '../../components/common/Steps';
import { OrderSummary } from '../../components/checkout/OrderSummary';

const steps = [
  { name: 'Shipping', state: 'shipping' },
  { name: 'Payment', state: 'payment' },
  { name: 'Confirmation', state: 'confirmation' },
];

export default function Checkout() {
  const location = useLocation();
  let state = 'shipping';
  let leftComponent = <CheckoutShipping />;
  if (location.pathname === PAGES.payment.href) {
    state = 'payment';
    leftComponent = <CheckoutPayment />;
  } else if (location.pathname.startsWith(PAGES.confirmation.href)) {
    state = 'confirmation';
    leftComponent = <>Under construction</>;
  }
  const isConfirmationPage = state === 'confirmation';

  return (
    <div className='bg-gray-50'>
      <div
        className={classNames(
          isConfirmationPage ? 'lg:max-w-3xl mx-auto' : 'lg:max-w-7xl',
          'max-w-2xl mx-auto pt-8 pb-24 px-4 sm:px-6 lg:px-8',
        )}
      >
        <h2 className='sr-only'>Checkout</h2>
        <Steps steps={steps} state={state} />
        <div className='lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16'>
          <div className={isConfirmationPage ? 'lg:col-span-2' : ''}>{leftComponent}</div>

          {/* Order summary */}
          {!isConfirmationPage && <OrderSummary editable={state === 'shipping'} />}
        </div>
      </div>
    </div>
  );
}
