import { z } from 'zod';
import { RefObject, forwardRef, useCallback } from 'react';
import {
  Address,
  AvailableCountriesQuery,
  useCreateCustomerAddressMutation,
  useUpdateCustomerAddressMutationMutation,
} from '~/generated/graphql';
import { Select, Input, Button, HighlightedButton } from '~/components/common';
import { useForm, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

export const addressScheme = z.object({
  fullName: z.string().min(1, { message: 'Name is required' }),
  city: z.string(),
  countryCode: z.string().min(1, { message: 'Country is required' }),
  postalCode: z.string(),
  province: z.string(),
  streetLine1: z.string().min(1, { message: 'Address is required' }),
  streetLine2: z.string(),
  phoneNumber: z.string(),
  company: z.string(),
});

type AddressType = z.infer<typeof addressScheme>;

export interface CustomerAddressFormProps {
  address?: Address;
  submit: () => void;
  availableCountries: AvailableCountriesQuery['availableCountries'];
}

export const CustomerAddressForm = forwardRef(({ address, submit, availableCountries }: CustomerAddressFormProps, ref) => {
  const [addAddress, addedAddress] = useCreateCustomerAddressMutation();
  const [updateAddress, updatedaddress] = useUpdateCustomerAddressMutationMutation();

  const formHook = useForm<AddressType>({
    resolver: zodResolver(addressScheme),
    defaultValues: {
      fullName: address?.fullName || undefined,
      city: address?.city || undefined,
      streetLine1: address?.streetLine1 || undefined,
      streetLine2: address?.streetLine2 || undefined,
      countryCode: address?.country?.code || undefined,
      postalCode: address?.postalCode || undefined,
      phoneNumber: address?.phoneNumber || undefined,
      company: address?.company || undefined,
      province: address?.province || undefined,
    },
  });
  const { handleSubmit } = formHook;
  const onSubmit: SubmitHandler<AddressType> = useCallback(
    async (data) => {
      if (address?.id) {
        await updateAddress({ variables: { input: { id: address.id, ...data } } });
      } else {
        await addAddress({
          variables: { input: data },
        });
      }

      submit();
    },
    [address],
  );
  return (
    <form id='editAddressForm' onSubmit={handleSubmit(onSubmit)} ref={ref as RefObject<HTMLFormElement>}>
      {/* <input type='hidden' name='intent' value='updateAddress' /> */}
      <div className='grid grid-cols-1 gap-y-2 my-8'>
        <div className='grid grid-cols-2 gap-x-2'>
          <Input label='Full name' name='fullName' required autoComplete='full-name' formHook={formHook} />
        </div>
        <Input label='Company' name='company' formHook={formHook} />
        <Input label='Address' name='streetLine1' required autoComplete='address-line1' formHook={formHook} />
        <Input label='Apartment, suite, etc.' name='streetLine2' autoComplete='address-line2' formHook={formHook} />
        <div className='grid grid-cols-[144px_1fr] gap-x-2'>
          <Input label='Postal code' name='postalCode' required autoComplete='postal-code' formHook={formHook} />
          <Input label='City' name='city' required autoComplete='locality' formHook={formHook} />
        </div>
        <Input label='Province / State' name='province' autoComplete='address-level1' formHook={formHook} />
        <Select
          name='countryCode'
          autoComplete='country'
          placeholder='Select a country...'
          required
          label='Country'
          formHook={formHook}
        >
          {availableCountries?.map((country) => (
            <option key={country.id} value={country.code}>
              {country.name}
            </option>
          ))}
        </Select>
        <Input label='Phone' name='phoneNumber' autoComplete='phone' formHook={formHook} />
        <Button type='button' onClick={submit}>
          Cancel
        </Button>
        <HighlightedButton type='submit' isSubmitting={addedAddress.loading || updatedaddress.loading}>
          Save
        </HighlightedButton>
      </div>
    </form>
  );
});
