import { ThemeProvider } from '@mui/material/styles';
import { themeAr, themeEn } from '~/constants/theme';
import CssBaseline from '@mui/material/CssBaseline';
import { useSelector } from 'react-redux';
import { localeSelector, AppLocale } from '~/store/app/reducer';
// import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';

export default function ThemeComponent({ children }: any) {
  const locale = useSelector(localeSelector);
  const isArabic = locale === AppLocale.AR;
  const theme = isArabic ? themeAr : themeEn;
  // Create rtl cache
  const cacheRtl = createCache({
    key: 'muirtl',
    // stylisPlugins: [prefixer, rtlPlugin],
    stylisPlugins: [prefixer],
  });

  const cached = isArabic ? <CacheProvider value={cacheRtl}>{children}</CacheProvider> : children;

  return (
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      {cached}
    </ThemeProvider>
  );
}
