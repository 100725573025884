import { useEffect, useState } from 'react';
import { LockClosedIcon } from '@heroicons/react/24/solid';
import { classNames } from '~/utils/class-names';
import { AddressForm } from '~/components/account/AddressForm';
import { ShippingMethodSelector } from '~/components/checkout/ShippingMethodSelector';
import { ShippingAddressSelector } from '~/components/checkout/ShippingAddressSelector';
import { useEligibleShippingMethodsQuery, useActiveCustomerAddressesQuery } from '~/generated/graphql';
import { useOrder } from '../../hooks/useOrder';
import { useNavigate } from 'react-router-dom';
import { PAGES } from '~/constants/pages';
import { CustomerForOrder } from '../../components/account/CustomerForOrder';

export default function CheckoutShipping() {
  const eligibleShippingMethodsHook = useEligibleShippingMethodsQuery();
  const eligibleShippingMethods = eligibleShippingMethodsHook.data?.eligibleShippingMethods || [];
  const activeCustomerHook = useActiveCustomerAddressesQuery();
  const activeCustomer = activeCustomerHook.data?.activeCustomer;
  const {
    fetchActiveOrderWorker: activeOrderFetcher,
    activeOrder,
    setOrderShippingMethod,
    setCheckoutShipping,
    isLoading,
    setOrderCustomer,
  } = useOrder();

  const [selectedAddressIndex, setSelectedAddressIndex] = useState<number>(-1);
  const [error, setError] = useState<any>();
  const navigate = useNavigate();

  const { customer, shippingAddress } = activeOrder ?? {};
  const isSignedIn = !!activeCustomer?.id;
  const addresses = activeCustomer?.addresses ?? []; // left for pickup default address
  const defaultFullName = shippingAddress?.fullName ?? (customer ? `${customer.firstName} ${customer.lastName}` : '');
  const canProceedToPayment =
    customer &&
    ((shippingAddress?.streetLine1 && shippingAddress?.postalCode) || selectedAddressIndex != null) &&
    activeOrder?.shippingLines?.length &&
    activeOrder?.lines?.length;

  useEffect(() => {
    activeOrderFetcher();
  }, []);

  useEffect(() => {
    if (selectedAddressIndex < 0 && addresses.length > 0) {
      let ndx = -1;
      if (shippingAddress?.streetLine1 && shippingAddress?.postalCode && shippingAddress?.fullName) {
        ndx = addresses.findIndex(
          (add) =>
            add.streetLine1 === shippingAddress.streetLine1 &&
            add.postalCode === shippingAddress.postalCode &&
            add.fullName === shippingAddress?.fullName,
        );
      } else {
        ndx = addresses.findIndex((add) => add.defaultShippingAddress);
      }
      if (ndx > 0) {
        ndx = 0;
      }
      setSelectedAddressIndex(ndx);
      submitSelectedAddress(ndx);
    }
  }, [addresses]);

  const submitCustomerForm = async (data: any) => {
    setError(undefined);
    const err = await setOrderCustomer(data);
    if (err) {
      setError(err);
    }
  };

  const submitSelectedAddress = (index: number) => {
    const selectedAddress = activeCustomer?.addresses?.[index];
    if (selectedAddress) {
      console.log(selectedAddress);
      setSelectedAddressIndex(index);
      setShippingAddress({ ...selectedAddress, countryCode: selectedAddress.country.code });
    }
  };

  async function setShippingAddress(formData: any) {
    await setCheckoutShipping(formData);
  }

  const submitSelectedShippingMethod = async (value?: string) => {
    if (value) {
      await setOrderShippingMethod(value);
    }
  };

  function navigateToPayment() {
    navigate(PAGES.payment.href);
  }

  return (
    <div className='max-w-6xl xl:mx-auto px-4'>
      <div>
        <h2 className='text-lg font-medium text-gray-900'>Contact information</h2>

        {isSignedIn ? (
          <div>
            <p className='mt-2 text-gray-600'>
              {customer?.firstName} {customer?.lastName}
            </p>
            <p>{customer?.emailAddress}</p>
          </div>
        ) : (
          <CustomerForOrder error={error} isEditable customer={customer} onSave={submitCustomerForm} isLoading={isLoading} />
        )}
      </div>

      <div className='mt-10 border-t border-gray-200 pt-10'>
        <h2 className='text-lg font-medium text-gray-900'>Shipping information</h2>
      </div>
      {isSignedIn && activeCustomer.addresses?.length ? (
        <div>
          <ShippingAddressSelector
            addresses={activeCustomer.addresses}
            selectedAddressIndex={selectedAddressIndex}
            onChange={submitSelectedAddress}
          />
        </div>
      ) : (
        <AddressForm
          onSave={setShippingAddress}
          isEditable
          address={shippingAddress}
          defaultFullName={defaultFullName}
          isLoading={isLoading}
        />
      )}

      <div className='mt-10 border-t border-gray-200 pt-10'>
        <ShippingMethodSelector
          eligibleShippingMethods={eligibleShippingMethods}
          currencyCode={activeOrder?.currencyCode}
          shippingMethodId={activeOrder?.shippingLines[0]?.shippingMethod.id}
          onChange={submitSelectedShippingMethod}
        />
      </div>

      <button
        type='button'
        disabled={!canProceedToPayment}
        onClick={navigateToPayment}
        className={classNames(
          canProceedToPayment ? 'bg-primary-600 hover:bg-primary-700' : 'bg-gray-400',
          'flex w-full items-center justify-center space-x-2 mt-24 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
        )}
      >
        <LockClosedIcon className='w-5 h-5'></LockClosedIcon>
        <span>Proceed to payment</span>
      </button>
    </div>
  );
}
