import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { PAGES } from '~/constants/pages';
import { useDebounce } from '~/hooks/useDebounce';
import { activeRouteSelector } from '~/store/app/reducer';
import { navigate } from '~/store/navigator';

export function SearchBar() {
  const [search, setSearch] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = useSelector(activeRouteSelector);

  useEffect(() => {
    const q = searchParams.get('q') || '';
    if (q !== search) {
      setSearch(q);
    }
  }, [searchParams]);

  const filterByQ = useDebounce(() => {
    if ([PAGES.search.id].includes(currentPage)) {
      if (search) {
        searchParams.set('q', search);
      } else {
        searchParams.delete('q');
      }
      setSearchParams(searchParams);
    } else {
      navigate(`${PAGES.search.href}?q=${search}`);
    }
  });

  const onSearchChange = (e: any) => {
    setSearch(e.target.value);
    filterByQ();
  };

  return (
    <input
      type='search'
      name='q'
      value={search}
      placeholder='Search'
      onChange={onSearchChange}
      className='shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md'
    />
  );
}
