import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Backdrop from '@mui/material/Backdrop';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
// import FooterComponent from 'components/Footer';
import { Header } from '../components/header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { isLoadingSelector } from '../store/app/reducer';
import Stack from '@mui/material/Stack';
// import { appActions } from '~/store/actions';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRootLoader } from '../hooks/useRootLoader';
import { Footer } from '../components/footer';
import { useOrder } from '~/hooks/useOrder';
import { activeOrderSelector } from '~/store/order/selectors';
import { CartTray } from '~/components/cart/CartTray';
import { useActiveCustomer } from '~/hooks/useActiveCustomer';

export default function MainPage({ children }: any) {
  const { activeChannel, collections, loading, menu } = useRootLoader();
  const { fetchActiveOrderWorker, adjustItem, removeItem } = useOrder();
  const { activeCustomer, fetchActiveCustomer } = useActiveCustomer();
  const activeOrder = useSelector(activeOrderSelector);
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingSelector);
  // const alerts = useSelector(alertsSelector);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchActiveOrderWorker();
    fetchActiveCustomer();
  }, []);

  // const hideAlert = (alertId: string) => dispatch(appActions.hideAlert(alertId));

  // const alersList = alerts.map((alert) => (
  //   <Alert severity={alert.type} key={alert.id} onClose={() => hideAlert(alert.id)}>
  //     {alert.message}
  //   </Alert>
  // ));

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading || isLoading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <Stack sx={{ width: '100%', pb: '75px' }} spacing={2}>
        <Header
          onCartIconClick={() => setOpen(!open)}
          cartQuantity={activeOrder?.totalQuantity ?? 0}
          collections={menu || []}
          activeCustomerId={activeCustomer?.id}
        />
        {/* {alersList} */}
        {children}
        <CartTray open={open} onClose={setOpen} adjustOrderLine={adjustItem} removeItem={removeItem} />
        <Footer collections={menu}></Footer>
      </Stack>
    </Box>
  );
}
