import { useDispatch } from 'react-redux';
import { redirect } from 'react-router-dom';
import { PAGES } from '~/constants/pages';
import {
  useLoginMutation,
  useLogoutMutation,
  useRegisterCustomerAccountMutation,
  useRequestPasswordResetMutation,
  useVerifyCustomerAccountMutation,
} from '~/generated/graphql';
import { appActions } from '~/store/app/reducer';
import { customerActions, Customer } from '~/store/customer/reducer';
import { navigate } from '~/store/navigator';
import { extractRegistrationFormValues } from '~/utils/registration-helper';
import { useActiveCustomer } from './useActiveCustomer';
import { useState } from 'react';

export const useLogin = () => {
  const dispatch = useDispatch();
  const [verifySuccess, setVerifySuccess] = useState(false);
  const [doLoginFn, doLoginObj] = useLoginMutation();
  const [doLogoutFn, doLogoutObj] = useLogoutMutation();
  const [doRegister, doRegisterObj] = useRegisterCustomerAccountMutation();
  const [doVerify, doVerifyObj] = useVerifyCustomerAccountMutation();
  const { fetchActiveCustomer } = useActiveCustomer();

  const login = async (body: FormData) => {
    const email = body.get('email');
    const password = body.get('password');
    if (typeof email === 'string' && typeof password === 'string') {
      const rememberMe = !!body.get('rememberMe');
      const redirectTo = (body.get('redirectTo') || PAGES.profile.href) as string;
      const d = await doLoginFn({ variables: { email, password, rememberMe }, fetchPolicy: 'network-only' });
      if (d.data?.login.__typename === 'CurrentUser') {
        dispatch(customerActions.updateActiveCustomer(d.data.login as unknown as Customer));
        await fetchActiveCustomer();

        navigate(redirectTo);
      } else {
        console.log('unauthorized');
      }
    }
  };

  const register = (form: FormData) => {
    const variables = extractRegistrationFormValues(form);
    doRegister({ variables }).then((d) => {
      if (d.data?.registerCustomerAccount.__typename === 'Success') {
        navigate(PAGES.registerSuccess.href);
      }
    });
  };

  const logout = () => {
    dispatch(customerActions.logout({ logout: doLogoutFn }));
  };

  const verify = async (token: string, password?: string) => {
    const d = await doVerify({ variables: { token, ...(password ? { password } : {}) } });
    if (d.data?.verifyCustomerAccount.__typename === 'CurrentUser') {
      await fetchActiveCustomer();
      setVerifySuccess(true);
    }
  };

  return {
    register,
    login,
    logout,
    isLoading: doLoginObj.loading || doLogoutObj.loading || doRegisterObj.loading,
    error: doLoginObj.error?.message || (doLoginObj.data?.login as any)?.message,
    verify,
    verifySuccess,
    verifyError: doVerifyObj?.error?.message,
  };
};
