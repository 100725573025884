import { FunctionComponent } from 'react';
import { ChevronRightIcon } from '@heroicons/react/24/solid';

export interface Step {
  name: string;
  state: string;
}

export interface StepsProps {
  steps: Step[];
  state: string;
}

export const Steps: FunctionComponent<StepsProps> = ({ steps, state }) => {
  return (
    <nav aria-label='Progress' className='hidden sm:block pb-8 mb-8 border-b'>
      <ol role='list' className='flex space-x-4 justify-center'>
        {steps.map((step, stepIdx) => (
          <li key={step.name} className='flex items-center'>
            {step.state === state ? (
              <span aria-current='page' className='text-primary-600'>
                {step.name}
              </span>
            ) : (
              <span>{step.name}</span>
            )}

            {stepIdx !== steps.length - 1 ? <ChevronRightIcon className='w-5 h-5 text-gray-300 ml-4' aria-hidden='true' /> : null}
          </li>
        ))}
      </ol>
    </nav>
  );
};
