import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProductQuery, useProductLazyQuery } from '~/generated/graphql';

export interface VariantOption {
  name: string;
  code: string;
}

export interface IVariant {
  name: string;
  code: string;
  options: VariantOption[];
}

export class Variant implements IVariant {
  name: string;
  code: string;
  options: VariantOption[];

  constructor(name: string, code: string, options?: VariantOption[]) {
    this.name = name;
    this.code = code;
    this.options = options || [];
  }

  getOptions() {
    return (this.options || []).map((o) => ({ label: o.name, value: o.code }));
  }

  getName() {
    return this.name;
  }

  getCode() {
    return this.code;
  }

  addOption(name: string, code: string) {
    if (!this.hasOption(code) && code !== 'category') {
      this.options.push({ name, code });
    }
  }

  hasOption(code: string) {
    const set = new Set(this.options.map((o) => o.code));
    return set.has(code);
  }
}

export interface UseProduct {
  product: ProductQuery['product'];
  error: any;
  loading: boolean;
  selectedVariantId: string | undefined;
  setSelectedVariantId: (id: string) => void;
  featuredAsset: any;
  setFeaturedAsset: (featuredAsset: any) => void;
  findVariantById: (id: string) => any;
  selectedVariant: any;
  options: Variant[];
  setSelectedVariants: (facetCode: string, optionCode: string) => void;
  selectedVariants: any;
}

export const useProduct = (): UseProduct => {
  const [selectedVariantId, setSelectedVariantId] = useState('');
  const [selectedVariants, setSelectedVariants] = useState({});
  const [featuredAsset, setFeaturedAsset] = useState<any>();
  const params = useParams();
  const [fetchProduct, { data, loading, error, called }] = useProductLazyQuery();

  const findVariantById = useCallback((id: string) => data?.product?.variants.find((v) => v.id === id), [data?.product]);

  const selectedVariant = useMemo(() => findVariantById(selectedVariantId), [selectedVariantId, selectedVariants]);

  const setSelectedVariantsImp = useCallback(
    (facetCode: string, optionCode: string) => {
      setSelectedVariants({ ...selectedVariants, [facetCode]: optionCode });
    },
    [selectedVariants],
  );

  const variantMap = useMemo(() => {
    const options: Map<string, Variant> = new Map();
    if (data?.product?.variants) {
      data.product.variants.forEach((prod, i) => {
        prod.facetValues.forEach((facet) => {
          if (!options.has(facet.facet.code)) {
            options.set(facet.facet.code, new Variant(facet.facet.name, facet.facet.code));
          }
          options.get(facet.facet.code)?.addOption(facet.name, facet.code);
        });
      });
    }
    return {
      options: Array.from(options.values()).filter((o) => o.code !== 'category'),
    };
  }, [data?.product?.variants]);

  useEffect(() => {
    if (params.slug && !called) {
      fetchProduct({ variables: { slug: params.slug } }).then((d) => console.log(d));
    }
  }, [params]);

  useEffect(() => {
    if (Object.keys(selectedVariants).length === variantMap.options.length && data?.product?.variants) {
      const variant = data.product.variants.find((prod) => {
        return Object.keys(selectedVariants).every((key) => {
          return !!prod.facetValues.find((facet) => facet.code === (selectedVariants as any)[key] && facet.facet.code === key);
        });
      });
      if (variant) {
        setSelectedVariantId(variant.id);
        setFeaturedAsset(variant.featuredAsset);
      }
    }
  }, [selectedVariants, variantMap.options, data?.product?.variants]);

  return {
    product: data?.product,
    error,
    loading,
    selectedVariantId,
    setSelectedVariantId,
    featuredAsset,
    setFeaturedAsset,
    findVariantById,
    selectedVariant,
    options: variantMap.options,
    setSelectedVariants: setSelectedVariantsImp,
    selectedVariants,
  };
};
