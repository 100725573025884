import debounce from 'lodash/debounce';
import { useEffect, useMemo, useRef } from 'react';

export const useDebounce = (callback: any) => {
  const ref: any = useRef();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, 500);
  }, []);

  return debouncedCallback;
};
