/* eslint-disable no-constant-condition */
import {
  CurrencyCode,
  ErrorCode,
  ErrorResult,
  useCreateStripePaymentIntentMutation,
  useEligiblePaymentMethodsQuery,
  useGenerateBraintreeClientTokenLazyQuery,
} from '~/generated/graphql';
import { StripePayments } from '~/components/checkout/stripe/StripePayments';
import { DummyPayments } from '~/components/checkout/DummyPayments';
import { BraintreeDropIn } from '~/components/checkout/braintree/BraintreePayments';
import { useEffect, useState } from 'react';
import { useOrder } from '~/hooks/useOrder';
import { LoadingComponent } from '~/components/common';
import { PAGES } from '~/constants/pages';
import { navigate } from '~/store/navigator';
import { usePayment } from '~/hooks/usePayment';

export default function CheckoutPayment() {
  const { data: eligiblePaymentMethodsData, loading } = useEligiblePaymentMethodsQuery({ fetchPolicy: 'network-only' });
  const eligiblePaymentMethods = eligiblePaymentMethodsData?.eligiblePaymentMethods || [];
  const [createStripePaymentIntent] = useCreateStripePaymentIntentMutation();
  const [generateBraintreeClientToken] = useGenerateBraintreeClientTokenLazyQuery();
  const [stripePaymentIntent, setStripePaymentIntent] = useState<string | undefined>();
  const [stripeError, setStripeError] = useState<string | undefined>();
  const [brainTreeKey, setBrainTreeKey] = useState<string | undefined>();
  const [brainTreeError, setBrainTreeError] = useState<string | undefined>();
  const [isReady, setIsReady] = useState(false);
  const paymentHook = usePayment();
  const isDev = process.env.NODE_ENV === 'development';
  const stripePublishableKey = isDev
    ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST
    : process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
  console.log(location.origin);

  const { fetchActiveOrderWorker, activeOrder, isLoading } = useOrder();

  async function loadPage() {
    await fetchActiveOrderWorker();
    setIsReady(true);
  }

  useEffect(() => {
    loadPage();
  }, []);

  useEffect(() => {
    const createStripeIntentLogic = async () => {
      if (!stripePaymentIntent && eligiblePaymentMethods) {
        if (eligiblePaymentMethods.find((method) => method.code.includes('stripe'))) {
          try {
            const stripePaymentIntentResult = await createStripePaymentIntent();
            setStripePaymentIntent(stripePaymentIntentResult.data?.createStripePaymentIntent ?? undefined);
          } catch (e: any) {
            setStripeError(e.message);
          }
        }

        if (eligiblePaymentMethods.find((method) => method.code.includes('braintree'))) {
          try {
            const generateBrainTreeTokenResult = await generateBraintreeClientToken();
            setBrainTreeKey(generateBrainTreeTokenResult.data?.generateBraintreeClientToken ?? undefined);
          } catch (e: any) {
            setBrainTreeError(e.message);
          }
        }
      }
    };
    createStripeIntentLogic();
  }, [eligiblePaymentMethods]);

  useEffect(() => {
    if (isReady) {
      if (!activeOrder || !activeOrder.active || activeOrder.lines.length === 0) {
        return navigate(PAGES.home.href);
      }
      const { customer, shippingAddress } = activeOrder ?? {};
      const canProceedToPayment =
        customer &&
        shippingAddress?.streetLine1 &&
        shippingAddress?.postalCode &&
        activeOrder?.shippingLines?.length &&
        activeOrder?.lines?.length;
      if (!canProceedToPayment) {
        return navigate(PAGES.checkout.href);
      }
    }
  }, [activeOrder, isReady]);

  if (loading || isLoading) {
    return <LoadingComponent />;
  }

  return (
    <div className='flex flex-col items-center divide-gray-200 divide-y'>
      {eligiblePaymentMethods.map((paymentMethod) => {
        if (paymentMethod.code.includes('braintree')) {
          return (
            <div className='py-3 w-full' key={paymentMethod.id}>
              {brainTreeError ? (
                <div>
                  <p className='text-red-700 font-bold'>Braintree error:</p>
                  <p className='text-sm'>{brainTreeError}</p>
                </div>
              ) : (
                <BraintreeDropIn
                  fullAmount={activeOrder?.totalWithTax ?? 0}
                  currencyCode={activeOrder?.currencyCode ?? ('USD' as CurrencyCode)}
                  show={true}
                  authorization={brainTreeKey!}
                />
              )}
            </div>
          );
        }
        if (paymentMethod.code.includes('stripe')) {
          return (
            <div className='py-12' key={paymentMethod.id}>
              {stripeError || !stripePaymentIntent || !stripePublishableKey ? (
                <div>
                  <p className='text-red-700 font-bold'>Stripe error:</p>
                  <p className='text-sm'>{stripeError}</p>
                </div>
              ) : (
                <StripePayments
                  paymentMethodCode={paymentMethod.code}
                  orderCode={activeOrder?.code || ''}
                  clientSecret={stripePaymentIntent!}
                  publishableKey={stripePublishableKey!}
                ></StripePayments>
              )}
            </div>
          );
        }
        return (
          <div className='py-12' key={paymentMethod.id}>
            <DummyPayments paymentMethod={paymentMethod} paymentHook={paymentHook} />
          </div>
        );
      })}
    </div>
  );
}
