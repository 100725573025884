import { XCircleIcon, ArrowPathIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import { useRequestPasswordResetMutation } from '~/generated/graphql';
import { Button } from '~/components/common';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [doReset, doResetObj] = useRequestPasswordResetMutation();
  const reset = () => {
    if (!email) {
      setError('A valid e-mail address is required.');
      return;
    }
    // if (!email || typeof email !== 'string' || !email.match(EMAIL_REGEX)) {
    //   setError('A valid e-mail address is required.');
    // }
    doReset({ variables: { emailAddress: email } });
  };

  useEffect(() => {
    if ((doResetObj?.data?.requestPasswordReset as any)?.message) {
      setError((doResetObj.data?.requestPasswordReset as any)?.message);
    }
    if (doResetObj?.error) {
      setError(doResetObj.error.message);
    }
  }, [doResetObj]);

  return (
    <div className='flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
      <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
        <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
          {doResetObj?.data?.requestPasswordReset?.__typename === 'Success' && (
            <div className='mb-6 bg-yellow-50 border border-yellow-400 text-yellow-800 rounded p-4 text-center text-sm'>
              <p>Password reset email sent to {email}</p>
            </div>
          )}
          <div className='space-y-6'>
            <div>
              <label className='block text-sm font-medium text-gray-700'>Email</label>
              <div className='mt-1'>
                <input
                  type='email'
                  autoComplete='email'
                  value={email}
                  required
                  onChange={(ev) => setEmail((ev.target as HTMLInputElement).value)}
                  onKeyUp={(ev) => {
                    setError('');
                    if (ev.key === 'Enter' && !!(ev.target as HTMLInputElement).value) {
                      reset();
                    }
                  }}
                  className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm'
                />
              </div>
            </div>

            {error !== '' && (
              <div className='rounded-md bg-red-50 p-4'>
                <div className='flex'>
                  <div className='flex-shrink-0'>
                    <XCircleIcon />
                  </div>
                  <div className='ml-3'>
                    <h3 className='text-sm font-medium text-red-800'>We ran into a problem resetting your password!</h3>
                    <p className='text-sm text-red-700 mt-2'>{error}</p>
                  </div>
                </div>
              </div>
            )}
            <div>
              <Button
                onClick={reset}
                className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'
              >
                <span className='flex gap-4 items-center'>
                  {doResetObj.loading && <ArrowPathIcon className='animate-spin h-5 w-5 text-gray-500' />}
                  Reset password
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
