import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appActions, isAppReadySelector } from '../store/app/reducer';
import MainPage from './MainPage';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Logger } from '../utils/helper';
import { PAGES } from '../constants/pages';
import { Home } from './home';
import { isNavigatorReady, setNavigation } from '../store/navigator';
import Loading from '../components/common/Loading';
import AuthComponent from './Auth';
import Collections from './collections/Collections';
import Search from './search/Search';
import Product from './product/Product';
import SignIn from './account/SignIn';
import SignUp from './account/SignUp';
import SignUpSuccess from './account/SignUpSuccess';
import Verify from './account/Verify';
import Account from './account/Account';
import { useRootLoader } from '../hooks/useRootLoader';
import { useOrder } from '~/hooks/useOrder';
import { useActiveCustomer } from '~/hooks/useActiveCustomer';
import ForgotPassword from './account/ForgotPassword';
import PasswordReset from './account/PasswordReset';
import AddAddress from './account/AddAddress';
import EditAddress from './account/EditAddress';
import CheckoutShipping from './checkout/Checkout';
import CheckoutConfirmation from './checkout/Confirmation';
import ThemeComponent from './Theme';
import { ProductReviewPage } from './product/ProductReview';
import ScrollToTop from './ScrollToTop';
import { Policy, TermOfUsage } from './policy-usage';

function PageWrapper({ route, children }: { route: string; children: any }) {
  const logger = Logger('PageWrapper');
  const dispatch = useDispatch();
  const location = useLocation();

  useRootLoader();
  const { fetchActiveOrderWorker } = useOrder();
  const { fetchActiveCustomer } = useActiveCustomer();

  useEffect(() => {
    logger('app mount', route);
    dispatch(appActions.toggleActiveRoute(route));
    fetchActiveOrderWorker();
    fetchActiveCustomer();
  }, [location.pathname]);

  return <>{children}</>;
}

export function Pages() {
  const logger = Logger('PageComponent');
  const navigator = useNavigate();
  const [isReady, setIsReady] = useState<boolean>(false);
  const isAppReady = useSelector(isAppReadySelector);

  const PAGES_COMPONENTS_PAGES: { [key: string]: any } = {
    [PAGES.home.id]: { ...PAGES.home, Component: Home },
    [PAGES.product.id]: { ...PAGES.product, Component: Product },
    [PAGES.collections.id]: { ...PAGES.collections, Component: Collections },
    [PAGES.search.id]: { ...PAGES.search, Component: Search },
    [PAGES.verify.id]: { ...PAGES.verify, Component: Verify },
    [PAGES.login.id]: { ...PAGES.login, Component: SignIn },
    [PAGES.register.id]: { ...PAGES.register, Component: SignUp },
    [PAGES.registerSuccess.id]: { ...PAGES.registerSuccess, Component: SignUpSuccess },
    [PAGES.forgotPassword.id]: { ...PAGES.forgotPassword, Component: ForgotPassword },
    [PAGES.passwordReset.id]: { ...PAGES.passwordReset, Component: PasswordReset },
    [PAGES.profile.id]: { ...PAGES.profile, Component: Account },
    [PAGES.newAddress.id]: { ...PAGES.newAddress, Component: AddAddress },
    [PAGES.addressInfo.id]: { ...PAGES.addressInfo, Component: EditAddress },
    [PAGES.checkout.id]: { ...PAGES.checkout, Component: CheckoutShipping },
    [PAGES.confirmation.id]: { ...PAGES.confirmation, Component: CheckoutConfirmation },
    [PAGES.addProductReview.id]: { ...PAGES.addProductReview, Component: ProductReviewPage },
    [PAGES.policy.id]: { ...PAGES.policy, Component: Policy },
    [PAGES.usage.id]: { ...PAGES.usage, Component: TermOfUsage },
  };

  useEffect(() => {
    setNavigation(navigator);
    setIsReady(true);
  }, []);

  if (isNavigatorReady() && !isReady) {
    setIsReady(true);
  }

  logger(isAppReady, isReady, isNavigatorReady());
  if (!isReady || !isAppReady) {
    return <Loading />;
  }

  const routes = Object.values(PAGES_COMPONENTS_PAGES).map((page) => {
    let element = (
      <PageWrapper route={page.id}>
        <page.Component />
      </PageWrapper>
    );
    if (page.withAuth) {
      element = (
        <PageWrapper route={page.id}>
          <AuthComponent>
            <page.Component />
          </AuthComponent>
        </PageWrapper>
      );
    }
    console.log(page.id, page.href);
    return <Route key={page.href} path={page.href} element={element} />;
  });
  return <Routes>{routes}</Routes>;
}

export default function PagesWrapper() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appActions.initializeTheApp());
  }, []);

  return (
    <ThemeComponent>
      <MainPage>
        <ScrollToTop />
        <Pages />
      </MainPage>
    </ThemeComponent>
  );
}
