/* eslint-disable camelcase */

import OrderHistoryItem from '~/components/account/OrderHistoryItem';
import { OrderList, OrderListOptions, SortOrder, useActiveCustomerOrderListLazyQuery } from '~/generated/graphql';
import { useEffect } from 'react';
import { PaginationComponent } from '~/components/common/Pagination';
import { usePagination } from '../../hooks/usePagination';

export default function AccountHistory() {
  const [doOrderList, orderListObj] = useActiveCustomerOrderListLazyQuery();
  const orderList = orderListObj.data?.activeCustomer?.orders || ([] as unknown as OrderList);
  const items = orderList?.items || [];
  const totalItems = orderList.totalItems || 0;
  const { page, limit, skip } = usePagination();

  useEffect(() => {
    const orderListOptions: OrderListOptions = {
      take: limit,
      skip, // Page is one-base-indexed so we gotta decrement first
      sort: { createdAt: SortOrder.Desc },
      filter: { active: { eq: false } },
    };
    doOrderList({ variables: { orderListOptions } });
  }, [page, limit, skip]);

  return (
    <div className='pt-10 relative'>
      {/* Loading-Overlay */}
      {orderListObj.loading && <div className='absolute top-0 left-0 w-full h-full z-100 bg-white bg-opacity-75'></div>}

      {items.length === 0 && (
        <div className='py-16 text-3xl text-center italic text-gray-300 select-none flex justify-center items-center'>
          {totalItems === 0 ? 'Your future orders will appear here' : 'No more orders, end reached'}
        </div>
      )}
      {/* The actual orders */}
      {items.map((item) => (
        // TODO: CHECK THIS ERROR OUT
        <OrderHistoryItem key={item.code} order={item} isInitiallyExpanded={true} className='mb-10' />
      ))}

      {/* Pagination */}

      <PaginationComponent total={totalItems} pageItems={items?.length || 0} />
    </div>
  );
}
