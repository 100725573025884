import { PencilIcon } from '@heroicons/react/24/outline';
import { useEffect, useRef, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { z } from 'zod';
import { Button, ErrorMessage, HighlightedButton, Input, SuccessMessage } from '~/components/common';
import { useUpdateCustomerPasswordMutationMutation } from '~/generated/graphql';
import { zodResolver } from '@hookform/resolvers/zod';

const passwordShchema = z
  .object({
    currentPassword: z.string().min(1, { message: 'Password is required' }),
    newPassword: z.string().min(1, { message: 'Password is required' }),
    confirmPassword: z.string().min(1, { message: 'Password is required' }),
  })
  .refine(({ newPassword, confirmPassword }) => newPassword === confirmPassword, {
    path: ['confirmPassword'],
    message: 'Passwords must match',
  });

type PasswordForm = z.infer<typeof passwordShchema>;

export default function AccountPassword() {
  const [editing, setEditing] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const formRef = useRef<HTMLFormElement>(null);
  const formHook = useForm<PasswordForm>({ resolver: zodResolver(passwordShchema) });
  const { formState, handleSubmit } = formHook;
  const [updatePassword, updatedPassword] = useUpdateCustomerPasswordMutationMutation();

  useEffect(() => {
    if (updatedPassword.data?.updateCustomerPassword.__typename === 'Success') {
      // show success message and reset form
      setIsSaved(true);
      setEditing(false);
      formRef.current?.reset();
    }
  }, [updatedPassword]);

  useEffect(() => {
    if (updatedPassword.error?.message) {
      setErrorMessage(updatedPassword.error.message);
    } else if (formState.errors.root?.message) {
      setErrorMessage(formState.errors.root.message);
    } else if (updatedPassword.data?.updateCustomerPassword && (updatedPassword?.data?.updateCustomerPassword as any).message) {
      setErrorMessage((updatedPassword?.data?.updateCustomerPassword as any).message);
    } else {
      setErrorMessage('');
    }
  }, [updatedPassword, formState.errors]);

  const update: SubmitHandler<PasswordForm> = async (data) => {
    await updatePassword({ variables: data });
  };

  // const errorMessage = updatedPassword.error?.message || formState.errors.root?.message;
  console.log(errorMessage, updatedPassword);

  return (
    <form ref={formRef} onSubmit={handleSubmit(update)}>
      <div className='p-4 space-y-4'>
        {editing && (
          <>
            <div className='gap-4 grid grid-cols-1 md:grid-cols-2'>
              <div>
                <Input formHook={formHook} required label='Current Password' name='currentPassword' type='password' />
              </div>
            </div>
            <div className='gap-4 grid grid-cols-1 md:grid-cols-2'>
              <div>
                <Input formHook={formHook} required label='New Password' name='newPassword' type='password' />
              </div>
              <div>
                <Input formHook={formHook} required label='Confirm Password' name='confirmPassword' type='password' />
              </div>
            </div>
          </>
        )}
        {isSaved && <SuccessMessage heading='Success!' message='Your password has been updated.' />}
        {errorMessage && <ErrorMessage heading='Password not updated.' message={errorMessage} />}
        {editing ? (
          <div className='flex gap-3'>
            <HighlightedButton type='submit' isSubmitting={updatedPassword.loading}>
              Save Password
            </HighlightedButton>
            <Button type='reset' onClick={() => setEditing(false)}>
              Cancel
            </Button>
          </div>
        ) : (
          <>
            <HighlightedButton type='button' onClick={() => setEditing(true)}>
              <PencilIcon className='w-4 h-4' /> Change Password
            </HighlightedButton>
          </>
        )}
      </div>
    </form>
  );
}
