import { HashtagIcon, MapPinIcon, ShoppingBagIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import { useEffect } from 'react';
import { TabProps } from '~/components/tabs/Tab';
import { TabsContainer } from '~/components/tabs/TabsContainer';
import { useActiveCustomer } from '~/hooks/useActiveCustomer';
import { useLogin } from '~/hooks/useLogin';
import AccountDetails from './AccountDetails';
import AccountHistory from './History';
import { AccountAddresses } from './Addresses';
import AccountPassword from './Password';

export default function AccountDashboard() {
  const { logout } = useLogin();
  const { activeCustomer, fetchActiveCustomer } = useActiveCustomer();

  useEffect(() => {
    fetchActiveCustomer();
  }, []);

  const tabs: TabProps[] = [
    {
      Icon: UserCircleIcon,
      text: 'Account Details',
      to: '',
      Component: AccountDetails,
    },
    {
      Icon: ShoppingBagIcon,
      text: 'Purchase History',
      to: 'history',
      Component: AccountHistory,
    },
    {
      Icon: MapPinIcon,
      text: 'Addresses',
      to: 'addresses',
      Component: AccountAddresses,
    },
    {
      Icon: HashtagIcon,
      text: 'Password',
      to: 'password',
      Component: AccountPassword,
    },
  ];

  return (
    <div className='max-w-6xl xl:mx-auto px-4'>
      <h2 className='text-3xl sm:text-5xl font-light text-gray-900 my-8'>My Account</h2>
      <p className='text-gray-700 text-lg -mt-4'>
        Welcome back, {activeCustomer?.firstName} {activeCustomer?.lastName}
      </p>
      <button onClick={logout} className='underline text-primary-600 hover:text-primary-800'>
        Sign out
      </button>
      <TabsContainer tabs={tabs} />
    </div>
  );
}
