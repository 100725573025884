import { useState } from 'react';
import { generatePath } from 'react-router-dom';
import { PAGES } from '~/constants/pages';
import {
  useNextOrderStatesLazyQuery,
  useTransitionOrderToStateMutation,
  useAddPaymentToOrderMutation,
  useOrderByCodeLazyQuery,
} from '~/generated/graphql';
import { navigate } from '~/store/navigator';

export const usePayment = () => {
  const [getNextOrderStates, nextOrderState] = useNextOrderStatesLazyQuery();
  const [transitionOrderToState, transitionOrderToStateObj] = useTransitionOrderToStateMutation();
  const [addPaymentToOrder, addPaymentToOrderObj] = useAddPaymentToOrderMutation();
  const [getOrderByCodeFn, getOrderByCodeObj] = useOrderByCodeLazyQuery();
  const [error, setError] = useState('');

  /*
    Once the customer is ready to pay, we need to transition the Order to the ArrangingPayment state.
    In this state, no further modifications are permitted.
    If you do need to modify the Order contents,
    you can always transition back to the AddingItems state:
  */

  const transitOrderToPymenet = async () => {
    resetError();
    const res1 = await getNextOrderStates();
    const nextOrderStates = res1.data?.nextOrderStates || [];
    if (nextOrderStates.includes('ArrangingPayment')) {
      const res2 = await transitionOrderToState({ variables: { state: 'ArrangingPayment' } });
      const transitionResult = res2.data?.transitionOrderToState;
      if (transitionResult?.__typename !== 'Order') {
        setError(transitionResult?.message || '');
        return;
      }
    }
  };

  const processPayment = async (paymentMethodCode: string, paymentNonce: string, skipRedirection?: boolean) => {
    await transitOrderToPymenet();

    // Finally, add a Payment to the Order:
    const res3 = await addPaymentToOrder({
      variables: { input: { method: paymentMethodCode, metadata: { nonce: paymentNonce } } },
    });
    if (res3.data?.addPaymentToOrder.__typename === 'Order') {
      if (!skipRedirection) {
        navigate(generatePath(PAGES.confirmation.href, { orderCode: res3.data.addPaymentToOrder.code }), true);
      }
    } else {
      setError(res3.data?.addPaymentToOrder.message || '');
    }
  };

  // Order status
  const getOrderByCode = async (orderCode: string) => {
    if (!orderCode) {
      setError('Missing order code!');
      return;
    }
    // await transitOrderToPymenet();
    await getOrderByCodeFn({ variables: { code: orderCode } });
  };

  const resetError = () => setError('');

  return {
    processingPayment:
      nextOrderState.loading || transitionOrderToStateObj.loading || addPaymentToOrderObj.loading || getOrderByCodeObj.loading,
    resetError,
    error,
    processPayment,
    transitOrderToPymenet,
    getOrderByCode,
    getOrderByCodeObj,
    order: getOrderByCodeObj.data?.orderByCode,
    networkError:
      nextOrderState.error || transitionOrderToStateObj.error || addPaymentToOrderObj.error || getOrderByCodeObj.error,
  };
};
