import { useEffect, useRef, useState } from 'react';
import { CheckIcon, PencilIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Button, ErrorMessage, HighlightedButton, Input } from '~/components/common';
import Modal from '~/components/modal/Modal';
import { Customer, useUpdateCustomerMutation } from '~/generated/graphql';
import { useActiveCustomer } from '~/hooks/useActiveCustomer';
import useToggleState from '~/hooks/useToggleState';
import { replaceEmptyString } from '~/utils/validation';
import FormElement from '../../components/common/FormElement';
import clsx from 'clsx';

type EmailSavedResponse = {
  newEmailAddress: string;
};

type FormError = {
  message: string;
  intent?: string;
};

enum FormIntent {
  UpdateEmail = 'updateEmail',
  UpdateDetails = 'updateDetails',
}

const changeEmailValidator = z.object({
  email: z.string().min(1, { message: 'Email is required' }).email('Must be a valid email').nonempty('Email is required'),
  password: z.string().min(1, { message: 'Password is required' }).nonempty('Password is required'),
});

type ChangeEmailType = z.infer<typeof changeEmailValidator>;

export const validator = z.object({
  title: z.string(),
  firstName: z.string().min(1, { message: 'First name is required' }).nonempty('First name is required'),
  lastName: z.string().min(1, { message: 'Last name is required' }).nonempty('Last name is required'),
  phoneNumber: z.string().nonempty('Phone number is required'),
});

type CustomerType = z.infer<typeof validator>;

const getInputClass = (error: boolean) =>
  clsx(
    'block w-full py-2 px-4 shadow-sm border bg-white rounded-md text-base sm:text-sm text-gray-900 border-gray-300 placeholder-gray-500',
    'focus:ring-primary-500 focus:border-primary-500 focus:outline-none focus:ring-2 focus:placeholder-gray-400',
    {
      'border-rose-500 focus:border-rose-500': error,
    },
  );

export default function AccountDetails() {
  const [emailSavedResponse, setEmailSavedResponse] = useState<EmailSavedResponse>();
  const [showChangeEmailModal, openChangeEmailModal, closeChangeEmailModal] = useToggleState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [doUpdateInfo, doUpdateInfoObj] = useUpdateCustomerMutation();
  const { fetchActiveCustomer } = useActiveCustomer();

  const { activeCustomer } = useActiveCustomer();

  const { firstName, lastName, title, phoneNumber, emailAddress } = (activeCustomer as Customer) || {};
  const fullName = `${title ? title + ' ' : ''}${firstName} ${lastName}`;

  const emailFormHook = useForm<ChangeEmailType>({
    resolver: zodResolver(changeEmailValidator),
  });

  const {
    register: registerEmail,
    handleSubmit: handleSubmitEmail,
    formState: { errors: emailErrors, isSubmitting: isSubmittingEmail },
  } = emailFormHook;

  const onSubmitEmail: SubmitHandler<ChangeEmailType> = (data) => {
    console.log(data);
  };

  const {
    register: registerCustomer,
    handleSubmit: handleSubmitCustomer,
    formState: { errors: customerErrors, isSubmitting: isSubmittingCustomer },
  } = useForm<CustomerType>({
    resolver: zodResolver(validator),
    defaultValues: {
      firstName,
      lastName,
      title: title || '',
      phoneNumber: phoneNumber || '',
    },
  });

  const onSubmitCustomer: SubmitHandler<CustomerType> = async (data) => {
    await doUpdateInfo({ variables: { input: data } });
    await fetchActiveCustomer();
    setIsEditing(false);
  };

  return (
    <>
      <Modal
        isOpen={showChangeEmailModal}
        close={() => closeChangeEmailModal()}
        // afterOpen={() => emailInputRef.current?.focus()}
        size='small'
      >
        <form onSubmit={handleSubmitEmail(onSubmitEmail)}>
          <Modal.Title>Change Email Address</Modal.Title>
          <Modal.Body>
            <div className='space-y-4 my-8'>
              <p>We will send a verification email to your new email address.</p>
              <p>
                Your current email address: <strong>{emailAddress}</strong>
              </p>

              <div className='space-y-1'>
                <Input
                  // ref={emailInputRef}
                  formHook={emailFormHook}
                  autoFocus
                  label='New Email Address'
                  required
                  error={emailErrors.password?.message}
                  {...registerEmail('email')}
                />
                <Input
                  formHook={emailFormHook}
                  label='Password'
                  type='password'
                  required
                  error={emailErrors.password?.message}
                  {...registerEmail('password')}
                />
                <input type='submit' hidden />
              </div>
              {emailErrors?.root?.message && (
                <ErrorMessage heading='We ran into a problem changing your E-Mail!' message={emailErrors.root.message} />
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button type='reset' onClick={() => closeChangeEmailModal()}>
              Cancel
            </Button>
            <HighlightedButton type='submit' isSubmitting={!!isSubmittingEmail}>
              Save
            </HighlightedButton>
          </Modal.Footer>
        </form>
      </Modal>

      <div className='space-y-10 p-4 mt-5'>
        <div className='grid grid-cols-2 gap-4'>
          <div className='col-span-2'>
            <h3 className='text-sm text-gray-500'>E-Mail</h3>
            {emailSavedResponse ? (
              <span>
                <span className='italic text-gray-800'>{emailSavedResponse.newEmailAddress}</span>
                <span className='ml-2 bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300'>
                  awaiting confirmation
                </span>
              </span>
            ) : (
              <span>{emailAddress}</span>
            )}
          </div>
          <div className='col-span-2'>
            <HighlightedButton type='button' onClick={() => openChangeEmailModal()}>
              <PencilIcon className='w-4 h-4' /> Change Email
            </HighlightedButton>
          </div>
        </div>
        <div className='border-t border-gray-200 pt-10'>
          <form onSubmit={handleSubmitCustomer(onSubmitCustomer)}>
            <div className='gap-4 grid sm:grid-cols-2'>
              {isEditing && (
                <div className='col-span-2'>
                  <FormElement name={'title'} label={'Title'} required error={customerErrors.title?.message}>
                    <input {...registerCustomer('title')} className={getInputClass(!!customerErrors.title?.message)} />
                  </FormElement>
                </div>
              )}
              {isEditing ? (
                <>
                  <div>
                    <FormElement name={'firstName'} label={'First Name'} required error={customerErrors.firstName?.message}>
                      <input {...registerCustomer('firstName')} className={getInputClass(!!customerErrors.firstName?.message)} />
                    </FormElement>
                  </div>
                  <div>
                    <FormElement name={'lastName'} label={'Last Name'} required error={customerErrors.lastName?.message}>
                      <input {...registerCustomer('lastName')} className={getInputClass(!!customerErrors.lastName?.message)} />
                    </FormElement>
                  </div>
                </>
              ) : (
                <div>
                  <h3 className='text-sm text-gray-500'>Full Name</h3>
                  {replaceEmptyString(fullName)}
                </div>
              )}

              <div>
                {isEditing ? (
                  <FormElement name={'phoneNumber'} label={'Phone Nr.'} required error={customerErrors.phoneNumber?.message}>
                    <input
                      {...registerCustomer('phoneNumber')}
                      className={getInputClass(!!customerErrors.phoneNumber?.message)}
                    />
                  </FormElement>
                ) : (
                  <div>
                    <h3 className='text-sm text-gray-500'>Phone Nr.</h3>
                    {replaceEmptyString(phoneNumber)}
                  </div>
                )}
              </div>
              <div className='col-span-2'>
                {isEditing ? (
                  <>
                    {customerErrors?.root?.message && (
                      <ErrorMessage
                        heading='We ran into a problem updating your details!'
                        message={customerErrors?.root?.message}
                      />
                    )}

                    <div className='flex gap-x-4'>
                      <HighlightedButton type='submit' isSubmitting={isSubmittingCustomer || doUpdateInfoObj.loading}>
                        <CheckIcon className='w-4 h-4' /> Save
                      </HighlightedButton>

                      <Button type='reset' onClick={() => setIsEditing(false)}>
                        <XMarkIcon className='w-4 h-4' /> Cancel
                      </Button>
                    </div>
                  </>
                ) : (
                  <HighlightedButton type='button' onClick={() => setIsEditing(true)}>
                    <PencilIcon className='w-4 h-4' /> Edit
                  </HighlightedButton>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
