import { XCircleIcon, ArrowPathIcon } from '@heroicons/react/24/solid';
import { Button } from '~/components/common';
import { useLogin } from '~/hooks/useLogin';
import { Link, useSearchParams } from 'react-router-dom';
import { SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import { isAuthorizedSelector } from '~/store/app/reducer';
import { navigate } from '~/store/navigator';
import { PAGES } from '~/constants/pages';

export default function SignInPage() {
  const [searchParams] = useSearchParams();
  // const login = useFetcher<ErrorResult>();
  const { login, isLoading, error } = useLogin();
  const isAuthorized = useSelector(isAuthorizedSelector);
  if (isAuthorized) {
    const redirectTo = (searchParams.get('redirectTo') || PAGES.profile.href) as string;
    navigate(redirectTo, true);
  }

  function handleChange(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    login(data);
  }

  const info = '';

  return (
    <>
      <div className='flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
        <div className='sm:mx-auto sm:w-full sm:max-w-md'>
          <h2 className='mt-6 text-center text-3xl text-gray-900'>Sign in to your account</h2>
          <p className='mt-2 text-center text-sm text-gray-600'>
            Or{' '}
            <Link to='/sign-up' className='font-medium text-primary-600 hover:text-primary-500'>
              register a new account
            </Link>
          </p>
        </div>

        <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
          <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
            {info && (
              <div className='bg-yellow-50 border border-yellow-400 text-yellow-800 rounded p-4 text-center text-sm'>
                <p>Demo credentials</p>
                <p>
                  Email address: <span className='font-bold'>test@vendure.io</span>
                </p>
                <p>
                  Password: <span className='font-bold'>test</span>
                </p>
              </div>
            )}
            <form onSubmit={handleChange}>
              <fieldset disabled={isLoading} className='space-y-6'>
                <input type='hidden' name='redirectTo' value={searchParams.get('redirectTo') ?? undefined} />
                <div>
                  <label htmlFor='email' className='block text-sm font-medium text-gray-700'>
                    Email address
                  </label>
                  <div className='mt-1'>
                    <input
                      id='email'
                      name='email'
                      type='email'
                      autoComplete='email'
                      required
                      defaultValue=''
                      placeholder='Email address'
                      className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm disabled:text-gray-400 disabled:bg-gray-200 disabled:cursor-not-allowed'
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor='password' className='block text-sm font-medium text-gray-700'>
                    Password
                  </label>
                  <div className='mt-1'>
                    <input
                      id='password'
                      name='password'
                      type='password'
                      autoComplete='current-password'
                      required
                      placeholder='Password'
                      defaultValue=''
                      className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm disabled:text-gray-400 disabled:bg-gray-200 disabled:cursor-not-allowed'
                    />
                  </div>
                </div>

                <div className='flex items-center justify-between'>
                  <div className='flex items-center'>
                    <input
                      id='rememberMe'
                      name='rememberMe'
                      type='checkbox'
                      className='h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded disabled:bg-gray-300 disabled:cursor-not-allowed'
                      defaultChecked
                    />
                    <label htmlFor='rememberMe' className='ml-2 block text-sm text-gray-900'>
                      Remember me
                    </label>
                  </div>

                  <div className='text-sm'>
                    <a href={PAGES.forgotPassword.href} className='font-medium text-primary-600 hover:text-primary-500'>
                      Forgot your password?
                    </a>
                  </div>
                </div>

                {error && !isLoading && (
                  <div className='rounded-md bg-red-50 p-4'>
                    <div className='flex'>
                      <div className='flex-shrink-0'>
                        <XCircleIcon className='h-5 w-5 text-red-400' aria-hidden='true' />
                      </div>
                      <div className='ml-3'>
                        <h3 className='text-sm font-medium text-red-800'>We ran into a problem signing you in!</h3>
                        <p className='text-sm text-red-700 mt-2'>{error}</p>
                      </div>
                    </div>
                  </div>
                )}

                <div>
                  <Button
                    type='submit'
                    className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'
                  >
                    <span className='flex gap-4 items-center'>
                      {isLoading && <ArrowPathIcon className='animate-spin h-5 w-5 text-gray-500' />}
                      Sign in
                    </span>
                  </Button>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
