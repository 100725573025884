/* This example requires Tailwind CSS v2.0+ */
import { Review } from './Review';
import { useReview } from '~/hooks/useReview';
import { PaginationComponent } from '~/components/common/Pagination';
import { Button } from '../common';
import { PAGES } from '~/constants/pages';
import { generatePath, Link } from 'react-router-dom';

export default function TopReviews() {
  const { reviews, totalReviews, canReview, slug } = useReview();
  const reviewLink = canReview ? (
    <Link
      to={generatePath(PAGES.addProductReview.href, { slug })}
      className='font-medium text-primary-600 hover:text-primary-500'
    >
      Write a customer review
    </Link>
  ) : (
    <></>
  );

  return (
    <div className=''>
      <div className='max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-6xl lg:px-8'>
        <div className='flex flex-row justify-between items-center gap-4'>
          <h2 className='text-lg font-medium text-gray-900'>Recent reviews</h2>
          {reviewLink}
        </div>

        <div className='mt-6 pb-10 border-t border-gray-200 divide-y divide-gray-200 space-y-10'>
          {reviews.length === 0 && (
            <div className='py-16 text-3xl text-center italic text-gray-300 select-none flex justify-center items-center'>
              {totalReviews === 0 ? 'Product not reviewd yet' : 'No more orders, end reached'}
            </div>
          )}
          {reviews.map((review) => review && <Review review={review as any} />)}
        </div>
      </div>
      {totalReviews > 0 && <PaginationComponent total={totalReviews} pageItems={reviews?.length || 0} />}
    </div>
  );
}
