import { useSearchParams } from 'react-router-dom';

export type TabProps = {
  Icon: any;
  text: string;
  to: string;
  Component?: any;
};

export function Tab({ Icon, text, to }: TabProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get('activeTab') || '';
  const isActive = activeTab === to;

  const navigate = (to: string) => {
    if (!to) {
      searchParams.delete('activeTab');
    } else {
      searchParams.set('activeTab', to);
    }

    setSearchParams(searchParams);
  };

  return (
    <li className={isActive ? 'cursor-default' : 'cursor-pointer'}>
      <button
        onClick={() => navigate(to)}
        className={`group w-full gap-x-2 max-w-[12rem] inline-flex items-center justify-around p-4 rounded-t-lg border-b-2 ${
          isActive ? 'text-primary-500 border-primary-500' : 'border-transparent hover:text-gray-600 hover:border-gray-300'
        }`}
      >
        <Icon className={`w-5 h-5 ${isActive ? 'text-primary-500' : 'text-gray-400 group-hover:text-gray-500'}`} />
        <p className='flex-1'>{text}</p>
      </button>
    </li>
  );
}
