import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeLatest, select, call } from 'redux-saga/effects';
import { appActions } from '~/store/app/reducer';
import i18next from '~/i18n';
import { Logger } from '~/utils/helper';
import { LogoutAction, customerActions } from './reducer';
import { PAGES } from '~/constants/pages';
import { navigate } from '~/store/navigator';

const logger = Logger('CustmerSaga');

export function* logout(action: PayloadAction<LogoutAction>) {
  try {
    yield put(appActions.showLoading());
    const logoutFn = action.payload.logout;
    yield call(logoutFn);
  } finally {
    navigate(PAGES.home.href);
    yield put(appActions.hideLoading());
    yield put(appActions.resetToken());
    yield put(customerActions.resetActiveCustomer());
  }
}

export default [takeLatest(customerActions.logout, logout)];
