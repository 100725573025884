import { XCircleIcon } from '@heroicons/react/24/solid';
import { RegisterValidationErrors, validateRegistrationForm } from '~/utils/registration-helper';
import { Link, useSearchParams } from 'react-router-dom';
import { useState, SyntheticEvent } from 'react';
import { useLogin } from '~/hooks/useLogin';

export default function SignUpPage() {
  const [formErrors, setFormErrors] = useState<RegisterValidationErrors>({});
  const [searchParams] = useSearchParams();
  const { register } = useLogin();

  const resetFormErrors = () => setFormErrors({});

  const handleSubmisstion = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const fieldErrors = validateRegistrationForm(data);
    if (Object.keys(fieldErrors).length !== 0) {
      setFormErrors(fieldErrors);
      return;
    }
    register(data);
  };

  const info = '';

  return (
    <>
      <div className='flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
        <div className='sm:mx-auto sm:w-full sm:max-w-md'>
          <h2 className='mt-6 text-center text-3xl text-gray-900'>Create a new account</h2>
          <p className='mt-2 text-center text-sm text-gray-600'>
            Or{' '}
            <Link to='/sign-in' className='font-medium text-primary-600 hover:text-primary-500'>
              login to your existing account
            </Link>
          </p>
        </div>

        <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
          <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
            {info && (
              <div className='bg-yellow-50 border border-yellow-400 text-yellow-800 rounded p-4 text-center text-sm'>
                <p>
                  Account registration is not supported by the demo Vendure instance. In order to use it, please connect the Remix
                  storefront to your own local / production instance.
                </p>
              </div>
            )}
            <form className='space-y-6' onSubmit={handleSubmisstion}>
              <input type='hidden' name='redirectTo' value={searchParams.get('redirectTo') ?? undefined} />
              <div>
                <label htmlFor='email' className='block text-sm font-medium text-gray-700'>
                  Email address
                </label>
                <div className='mt-1'>
                  <input
                    id='email'
                    name='email'
                    type='email'
                    autoComplete='email'
                    onChange={resetFormErrors}
                    className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm'
                  />
                  {formErrors?.email && <div className='text-xs text-red-700'>{formErrors.email}</div>}
                </div>
              </div>

              <div>
                <label htmlFor='firstName' className='block text-sm font-medium text-gray-700'>
                  First name
                </label>
                <div className='mt-1'>
                  <input
                    id='firstName'
                    name='firstName'
                    type='text'
                    autoComplete='given-name'
                    onChange={resetFormErrors}
                    className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm'
                  />
                </div>
              </div>

              <div>
                <label htmlFor='lastName' className='block text-sm font-medium text-gray-700'>
                  Last name
                </label>
                <div className='mt-1'>
                  <input
                    id='lastName'
                    name='lastName'
                    type='text'
                    autoComplete='family-name'
                    onChange={resetFormErrors}
                    className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm'
                  />
                </div>
              </div>

              <div>
                <label htmlFor='password' className='block text-sm font-medium text-gray-700'>
                  Password
                </label>
                <div className='mt-1'>
                  <input
                    id='password'
                    name='password'
                    type='password'
                    autoComplete='current-password'
                    onChange={resetFormErrors}
                    className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm'
                  />
                  {formErrors?.password && <div className='text-xs text-red-700'>{formErrors.password}</div>}
                </div>
              </div>
              <div>
                <label htmlFor='repeatPassword' className='block text-sm font-medium text-gray-700'>
                  Repeat Password
                </label>
                <div className='mt-1'>
                  <input
                    id='repeatPassword'
                    name='repeatPassword'
                    type='password'
                    autoComplete='current-password'
                    className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm'
                  />
                  {formErrors?.repeatPassword && <div className='text-xs text-red-700'>{formErrors.repeatPassword}</div>}
                </div>
              </div>
              {formErrors?.form && (
                <div className='rounded-md bg-red-50 p-4'>
                  <div className='flex'>
                    <div className='flex-shrink-0'>
                      <XCircleIcon className='h-5 w-5 text-red-400' aria-hidden='true' />
                    </div>
                    <div className='ml-3'>
                      <h3 className='text-sm font-medium text-red-800'>We ran into a problem while creating your account!</h3>
                      <p className='text-sm text-red-700 mt-2'>{formErrors.form}</p>
                    </div>
                  </div>
                </div>
              )}

              <div>
                <button
                  type='submit'
                  className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'
                >
                  Sign up
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
