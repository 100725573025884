import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { XCircleIcon, ArrowPathIcon } from '@heroicons/react/24/solid';
import { useResetPasswordMutation } from '~/generated/graphql';
import { navigate } from '~/store/navigator';
import { PAGES } from '~/constants/pages';
import { Button } from '~/components/common';

export default function PasseordReset() {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [searchParams] = useSearchParams();
  const [doReset, doResetObj] = useResetPasswordMutation();

  const reset = useCallback(() => {
    if (!password || typeof password !== 'string' || password.length < 4) {
      setError('Minimum password length is 4 symbols.');
      return;
    }
    doReset({ variables: { password, token: searchParams.get('token') || '' } });
  }, [password]);

  useEffect(() => {
    if (doResetObj.error) {
      setError(doResetObj.error.message);
    }
    if (doResetObj.data?.resetPassword.__typename === 'CurrentUser') {
      navigate(PAGES.profile.href, true);
    }
  }, [doResetObj]);

  return (
    <div className='flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
      <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
        <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
          <h2 className='mt-6 text-center text-3xl text-gray-900'>Reset password</h2>
          <p className='mt-2 text-center text-sm text-gray-600'>Choose a new password</p>
          <div>
            <div className='mt-1 mb-8'>
              <input
                type='password'
                value={password}
                required
                onChange={(ev) => setPassword((ev.target as HTMLInputElement).value)}
                onKeyUp={(ev) => {
                  setError('');
                  if (ev.key === 'Enter' && !!(ev.target as HTMLInputElement).value) {
                    reset();
                  }
                }}
                className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm'
              />
            </div>
          </div>
          {error !== '' && (
            <div className='rounded-md bg-red-50 p-4 mb-8'>
              <div className='flex'>
                <div className='flex-shrink-0'>
                  <XCircleIcon />
                </div>
                <div className='ml-3'>
                  <h3 className='text-sm font-medium text-red-800'>We ran into a problem verifying your account!</h3>
                  <p className='text-sm text-red-700 mt-2'>{error}</p>
                </div>
              </div>
            </div>
          )}
          <div>
            <Button
              onClick={reset}
              className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'
            >
              <span className='flex gap-4 items-center'>
                {doResetObj.loading && <ArrowPathIcon className='animate-spin h-5 w-5 text-gray-500' />}
                Reset password
              </span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
