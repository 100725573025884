import { useRef, useEffect } from 'react';
import Modal from '~/components/modal/Modal';
import useToggleState from '~/hooks/useToggleState';
import { CustomerAddressForm } from '~/components/account/CustomerAddressForm';
import { useAvailableCountriesQuery, useCreateCustomerAddressMutation } from '~/generated/graphql';
import { useNavigate } from 'react-router-dom';

export default function NewAddress() {
  const { data } = useAvailableCountriesQuery();
  const [addAddress, addedAddress] = useCreateCustomerAddressMutation();
  const availableCountries = data?.availableCountries || [];
  const navigate = useNavigate();
  const { state, close } = useToggleState(true);

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (addedAddress.data?.createCustomerAddress.__typename === 'Address') {
      close();
    }
  }, [addedAddress]);

  const afterClose = () => {
    navigate(-1);
  };

  return (
    <div>
      <Modal isOpen={state} close={close} afterClose={afterClose}>
        <Modal.Title>New address</Modal.Title>
        <Modal.Body>
          <CustomerAddressForm availableCountries={availableCountries} submit={close} ref={formRef}></CustomerAddressForm>
        </Modal.Body>
      </Modal>
    </div>
  );
}
