import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, from } from '@apollo/client';
import { baseURL } from './constants/api';
import { store } from './App';
import { appActions, tokenSelector } from './store/app/reducer';
import { Logger } from '~/utils/helper';
import { encrypt, decrypt } from './utils/encriptor';

const logger = Logger('ApiService');

const fetcher = async (url: string, options: RequestInit): Promise<Response | any> => {
  if (store) {
    const tokenInfo = tokenSelector(store.getState());
    const token = tokenInfo.token;
    if (token) {
      (options.headers as any).Authorization = `Bearer ${token}`;
    }
  }
  if (options.body) {
    options.body = `{ "q": "${encrypt(options.body)}" }`;
  }
  const response = await fetch(`${baseURL}${url}`, options);
  console.log('moj header', response?.headers.get('vendure-auth-token'));
  if (response?.headers.get('vendure-auth-token')) {
    const token = response.headers.get('vendure-auth-token');
    if (store && token && store.dispatch) {
      store.dispatch(appActions.setToken({ token, userId: '', active: true }));
    }
  }
  const txt = await response.text();
  const descryptedTxt = decrypt(txt);
  return {
    ...response,
    text: () => Promise.resolve(descryptedTxt),
  };
};
const httpLink = new HttpLink({ fetch: fetcher });

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpLink,
});

// import {
//   ApolloClient,
//   ApolloLink,
//   HttpLink,
//   InMemoryCache,
// } from '@apollo/client'

/* another way */
// import { setContext } from '@apollo/client/link/context';

// const httpLink = new HttpLink({
//   uri: baseURL,
//   credentials: 'include',
// });

// const afterwareLink = new ApolloLink((operation, forward) => {
//   return forward(operation).map((response) => {
//     const context = operation.getContext();
//     const authHeader = context.response.headers.get('vendure-auth-token');
//     if (authHeader && store && typeof store.dispatch === 'function') {
//       // If the auth token has been returned by the Vendure
//       // server, we store it in localStorage
//       store.dispatch(appActions.setToken({ token: authHeader, userId: '', active: true }));
//     }
//     return response;
//   });
// });

// const requestMiddleware = new ApolloLink((operation, forward) => {
//   // add the authorization to the headers
//   operation.setContext(({ headers = {} }) => {
//     let token;
//     if (store) {
//       const tokenInfo = tokenSelector(store.getState());
//       token = tokenInfo.token;
//     }
//     return {
//       headers: {
//         ...headers,
//         authorization: token ? `Bearer ${token}` : '',
//       },
//     };
//   });
//   const context = operation.getContext();
//   console.log(operation);

//   return forward(operation).map((request) => {
//     console.log(request);
//     return request;
//   });
// });

// export const client = new ApolloClient({
//   link: from([requestMiddleware, afterwareLink, httpLink]),
//   cache: new InMemoryCache(),
// });
