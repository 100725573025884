import { PayloadAction, createAction, createReducer } from '@reduxjs/toolkit';
import { ActiveOrderQuery } from '~/generated/graphql';
import { resetAllStateAction } from '../actions';

export type Order = ActiveOrderQuery['activeOrder'];

export interface OrderState {
  activeOrder: ActiveOrderQuery['activeOrder'] | undefined;
}

const namespace = 'order';

export const orderActions = {
  fetchActiveOrder: createAction(`${namespace}/fetchActiveOrder`),
  updateActiveOrder: createAction<Order>(`${namespace}/updateActiveOrder`),
};

export const orderInitState: OrderState = {
  activeOrder: undefined,
};

export const orderReducer = createReducer(orderInitState, (builder) => {
  builder
    .addCase(resetAllStateAction, (state: OrderState) => {
      state.activeOrder = undefined;
    })
    .addCase(orderActions.updateActiveOrder, (state: OrderState, action: PayloadAction<Order>) => {
      state.activeOrder = action.payload;
    });
});
