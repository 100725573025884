/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FunctionComponent, useEffect } from 'react';
import { CartContents } from '~/components/cart/CartContents';
import { CartTotals } from '~/components/cart/CartTotals';
import { useOrder } from '../../hooks/useOrder';

export interface OrderSummaryProps {
  editable?: boolean;
}

export const OrderSummary: FunctionComponent<OrderSummaryProps> = ({ editable }) => {
  const { fetchActiveOrderWorker: activeOrderFetcher, activeOrder, adjustItem, removeItem } = useOrder();

  useEffect(() => {
    activeOrderFetcher();
  }, []);

  return (
    <div className='mt-10 lg:mt-0'>
      <h2 className='text-lg font-medium text-gray-900 mb-4'>Order summary</h2>

      <CartContents
        orderLines={activeOrder?.lines ?? []}
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        currencyCode={activeOrder?.currencyCode!}
        editable={!!editable}
        removeItem={removeItem}
        adjustOrderLine={adjustItem}
      ></CartContents>
      <CartTotals order={activeOrder}></CartTotals>
    </div>
  );
};
