import { Stack, Typography, Chip } from '@mui/material';

export const VariantOption = ({
  value,
  handleSelect,
  options,
  label,
}: {
  label: string;
  value?: string;
  options: Array<{ label: string; value: string }>;
  handleSelect?: (option: any) => void;
}) => {
  return (
    <Stack spacing={2} className='my-5'>
      <Typography
        variant='button'
        display='block'
        sx={{ fontWeight: 700 }}
        className="className='block text-sm font-medium text-gray-700'"
      >
        {label}:
      </Typography>
      <Stack direction='row' spacing={1} sx={{ flexWrap: 'wrap', gap: 1 }}>
        {(options || []).map((op) => (
          <Chip
            key={op.value}
            label={op.label}
            color={value === op.value ? 'primary' : undefined}
            variant={value === op.value ? undefined : 'outlined'}
            onClick={() => handleSelect && handleSelect(op)}
          />
        ))}
      </Stack>
    </Stack>
  );
};
