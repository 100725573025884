import { ArrowPathIcon, CreditCardIcon, PencilIcon, TrashIcon, TruckIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useState, SyntheticEvent, useCallback } from 'react';
import {
  Address,
  ErrorResult,
  useActiveCustomerAddressesLazyQuery,
  useDeleteCustomerAddressMutation,
  useUpdateCustomerAddressMutationMutation,
} from '~/generated/graphql';
import { Button, ErrorMessage, HighlightedButton } from '~/components/common';
import Modal from '../modal/Modal';
import { Link, generatePath } from 'react-router-dom';
import { PAGES } from '~/constants/pages';

type EditAddressProps = {
  address: Address;
  isActive?: boolean;
};

export default function EditAddressCard({ address, isActive = false }: EditAddressProps) {
  const [isDeleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);

  const [updateAddress, updatedAddress] = useUpdateCustomerAddressMutationMutation();
  const [deleteAddress, deletedAddress] = useDeleteCustomerAddressMutation();
  const [fetchAdresses] = useActiveCustomerAddressesLazyQuery({ fetchPolicy: 'network-only' });

  const setDefaultShipping = useCallback(async () => {
    if (!address || !address.id) {
      return;
    }
    await updateAddress({ variables: { input: { id: address.id, defaultShippingAddress: true } } });
    await fetchAdresses();
  }, [address]);

  const setDefaultdefaultBillingAddress = useCallback(async () => {
    if (!address || !address.id) {
      return;
    }
    await updateAddress({ variables: { input: { id: address.id, defaultBillingAddress: true } } });
    await fetchAdresses();
  }, [address]);

  const deleteAddressFn = useCallback(async () => {
    if (!address || !address.id) {
      return;
    }
    await deleteAddress({ variables: { id: address.id } });
    await fetchAdresses();
  }, [address]);

  const deleteMessage = deletedAddress.error?.message;

  return (
    <>
      {/* Note: Only allow closing when it isnt loading to prevent accidental closing via outside-click */}
      <Modal isOpen={isDeleteModalVisible} close={() => setDeleteModalVisible(!deletedAddress.loading ? false : true)}>
        <Modal.Title>Remove Address</Modal.Title>
        <Modal.Body>
          <div className='space-y-4 my-4'>
            Do you want to remove this address?
            <input type='hidden' name='id' value={address.id} />
            {deleteMessage && (
              <ErrorMessage heading='Address could not be removed' message={deleteMessage ?? 'Something went wrong.'} />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type='button' onClick={() => setDeleteModalVisible(false)} disabled={deletedAddress.loading}>
            Cancel
          </Button>
          <HighlightedButton
            onClick={deleteAddressFn}
            name='_action'
            value='deleteAddress'
            disabled={deletedAddress.loading}
            isSubmitting={deletedAddress.loading}
          >
            Yes
          </HighlightedButton>
        </Modal.Footer>
      </Modal>
      <div
        className={clsx(
          'border border-gray-200 p-5 min-h-[220px] h-full w-full flex flex-col justify-between gap-8 transition-colors',
          {
            'border-gray-900': isActive,
          },
        )}
      >
        <div className='flex justify-between'>
          {/* Customer Data Section */}
          <div className='flex flex-col'>
            <span className='text-left text-base-semi'>{address.fullName}</span>
            {address.company && <span className='text-small-regular text-gray-700'>{address.company}</span>}
            <div className='flex flex-col text-left text-base-regular mt-2'>
              <span>
                {address.streetLine1}
                {address.streetLine2 && <span>, {address.streetLine2}</span>}
              </span>
              <span>
                {address.postalCode}, {address.city}
              </span>
              <span>
                {address.province && `${address.province}, `}
                {address.country?.code?.toUpperCase()}
              </span>
            </div>
          </div>
          {/* Default Shipping/Billing Section */}
          {(address.defaultShippingAddress || address.defaultBillingAddress) && (
            <div className='text-end text-gray-500 uppercase tracking-wider'>
              <span className='block text-sm font-medium'>Default</span>
              <span className='block text-xs mt-1'>
                {address.defaultShippingAddress && 'Shipping'}
                {address.defaultShippingAddress && address.defaultBillingAddress && (
                  <>
                    <br />
                    &amp;&nbsp;
                  </>
                )}
                {address.defaultBillingAddress && 'Billing'}
              </span>
            </div>
          )}
        </div>
        {/* CRUD Actions */}
        <div className='flex flex-col md:flex-row items-start gap-4'>
          <div className='flex items-center gap-4'>
            <Link
              role='button'
              preventScrollReset
              className='text-gray-700 flex items-center gap-x-2'
              to={generatePath(PAGES.addressInfo.href, { addressId: address.id })}
            >
              <PencilIcon className='w-4 h-4'></PencilIcon>
              Edit
            </Link>
            <button
              type='button'
              title='Delete this address'
              className='text-gray-700 flex items-center gap-x-2'
              disabled={deletedAddress.loading}
              onClick={() => setDeleteModalVisible(true)}
            >
              {!deletedAddress.loading ? (
                <TrashIcon className='w-4 h-4'></TrashIcon>
              ) : (
                <ArrowPathIcon className='w-4 h-4 animate-spin'></ArrowPathIcon>
              )}
              Remove
            </button>
          </div>
          {(!address.defaultShippingAddress || !address.defaultBillingAddress) && (
            <div>
              <span className='text-gray-500 flex gap-4'>
                {/* Default shipping */}
                {!address.defaultShippingAddress && (
                  <button
                    name='_action'
                    value='setDefaultShipping'
                    onClick={setDefaultShipping}
                    title='Set as default shipping address'
                    className='text-gray-700 flex items-center gap-2'
                    disabled={updatedAddress.loading}
                  >
                    {!updatedAddress.loading ? (
                      <TruckIcon className='w-4 h-4'></TruckIcon>
                    ) : (
                      <ArrowPathIcon className='w-4 h-4 animate-spin'></ArrowPathIcon>
                    )}
                    Shipping
                  </button>
                )}

                {!address.defaultBillingAddress && (
                  <button
                    name='_action'
                    value='setDefaultBilling'
                    onClick={setDefaultdefaultBillingAddress}
                    title='Set as default billing address'
                    className='text-gray-700 flex items-center gap-2'
                    disabled={updatedAddress.loading}
                  >
                    {!updatedAddress.loading ? (
                      <CreditCardIcon className='w-4 h-4'></CreditCardIcon>
                    ) : (
                      <ArrowPathIcon className='w-4 h-4 animate-spin'></ArrowPathIcon>
                    )}
                    Billing
                  </button>
                )}
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
