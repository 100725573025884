import { useState } from 'react';
import {
  CreateAddressInput,
  useActiveOrderLazyQuery,
  useActiveOrderQuery,
  useAddItemToOrderMutation,
  useAdjustOrderLineMutation,
  useRemoveOrderLineMutation,
  useSetCustomerForOrderMutation,
  useSetOrderShippingAddressMutation,
  useSetOrderShippingMethodMutation,
} from '../generated/graphql';
import { useDispatch } from 'react-redux';
import { orderActions } from '~/store/order/reducer';

export const useOrder = () => {
  const dispatch = useDispatch();
  const [fetchActiveOrder, { data: activeOrder, loading, error }] = useActiveOrderLazyQuery();
  const [addItemToOrderMutation, addItemToOrderObj] = useAddItemToOrderMutation();
  const [adjustItemFn, adjustItemObj] = useAdjustOrderLineMutation();
  const [removeItemFn, removeItemObj] = useRemoveOrderLineMutation();
  const [setOrderShippingMethodFn, setOrderShippingMethodObj] = useSetOrderShippingMethodMutation();
  const [setCheckoutShippingFn, setCheckoutShippingObj] = useSetOrderShippingAddressMutation();
  const [setOrderCustomerFn, setOrderCustomerObj] = useSetCustomerForOrderMutation();

  const fetchActiveOrderWorker = () =>
    fetchActiveOrder({ fetchPolicy: 'network-only' }).then((dd) =>
      dispatch(orderActions.updateActiveOrder(dd.data?.activeOrder)),
    );

  const addItemToOrder = (productVariantId: string, quantity: number) => {
    addItemToOrderMutation({ variables: { productVariantId, quantity } }).then((d) => {
      fetchActiveOrderWorker();
    });
  };

  const adjustItem = (lineId: string, quantity: number) => {
    adjustItemFn({ variables: { orderLineId: lineId, quantity } }).then(() => fetchActiveOrderWorker());
  };

  const removeItem = (lineId: string) => {
    removeItemFn({ variables: { orderLineId: lineId } }).then(() => fetchActiveOrderWorker());
  };

  const setOrderShippingMethod = async (shippingMethodId: string) => {
    const result = await setOrderShippingMethodFn({ variables: { shippingMethodId } });
    await fetchActiveOrderWorker();
    if (result.data?.setOrderShippingMethod.__typename === 'Order') {
      return null;
    }
    return result.data?.setOrderShippingMethod;
  };

  const setCheckoutShipping = async (shippingFormData: any) => {
    const result = await setCheckoutShippingFn({
      variables: {
        input: {
          city: shippingFormData.city,
          company: shippingFormData.company,
          countryCode: shippingFormData.countryCode,
          customFields: shippingFormData.customFields,
          fullName: shippingFormData.fullName,
          phoneNumber: shippingFormData.phoneNumber,
          postalCode: shippingFormData.postalCode,
          province: shippingFormData.province,
          streetLine1: shippingFormData.streetLine1,
          streetLine2: shippingFormData.streetLine2,
        },
      },
    });
    await fetchActiveOrderWorker();
    if (result.data?.setOrderShippingAddress.__typename === 'Order') {
      return null;
    }
    return result.data?.setOrderShippingAddress;
  };

  const setOrderCustomer = async (customer: any) => {
    const result = await setOrderCustomerFn({ variables: { input: customer } });
    if (result.data?.setCustomerForOrder.__typename === 'Order') {
      return null;
    }
    return result.data?.setCustomerForOrder;
  };

  return {
    activeOrder: activeOrder?.activeOrder,
    fetchActiveOrderWorker,
    addItemToOrder,
    adjustItem,
    removeItem,
    setOrderShippingMethod,
    setCheckoutShipping,
    setOrderCustomer,
    isLoading:
      loading ||
      addItemToOrderObj.loading ||
      adjustItemObj.loading ||
      setCheckoutShippingObj.loading ||
      setOrderCustomerObj.loading,
    error: error || addItemToOrderObj.error || adjustItemObj.error,
  };
};
