import { useState, useRef, RefObject, useEffect, useCallback } from 'react';
import { Price } from '~/components/products/Price';
import { CheckIcon, HeartIcon, PhotoIcon } from '@heroicons/react/24/solid';
import { APP_META_TITLE } from '~/constants/app';
import { ErrorCode, ErrorResult, Product } from '~/generated/graphql';
import { Alert, Breadcrumbs } from '~/components/common';
import { StockLevelLabel } from '~/components/products/StockLevelLabel';
import TopReviews from '~/components/products/TopReviews';
import { useProduct } from '../../hooks/useProduct';
import { Helmet } from 'react-helmet';
import { useOrder } from '~/hooks/useOrder';
import { useSelector } from 'react-redux';
import { activeOrderSelector } from '~/store/order/selectors';
import { VariantOption } from '../../components/product/VariantOption';
import { CatchBoundary } from '~/components/product/CatchBoundary';
import { ImageGallery } from '../../components/product/ImageGallery';

export default function ProductSlug() {
  const {
    product,
    error,
    loading,
    selectedVariantId,
    selectedVariant,
    featuredAsset,
    setFeaturedAsset,
    options,
    selectedVariants,
    setSelectedVariants,
  } = useProduct();
  const { isLoading: isLoadingOrder, addItemToOrder, error: addError } = useOrder();
  const addItemToOrderError = getAddItemToOrderError(addError as any);
  const activeOrder = useSelector(activeOrderSelector);
  console.log(product);

  if (!product) {
    return <CatchBoundary />;
  }

  const qtyInCart = activeOrder?.lines.find((l: any) => l.productVariant.id === selectedVariantId)?.quantity ?? 0;
  console.log(options, selectedVariants);
  const variants = options.filter((o) => o.code !== 'category');

  return (
    <div>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{`${product?.name} - ${APP_META_TITLE}`}</title>
      </Helmet>
      <div className='max-w-6xl mx-auto px-4'>
        <h2 className='text-3xl sm:text-5xl font-light tracking-tight text-gray-900 my-8'>{product.name}</h2>
        <Breadcrumbs items={product.collections[product.collections.length - 1]?.breadcrumbs ?? []}></Breadcrumbs>
        <div className='lg:grid lg:grid-cols-2 lg:gap-x-8 lg:items-start mt-4 md:mt-12'>
          {/* Image gallery */}
          {product && (
            <ImageGallery product={product as Product} featuredAsset={featuredAsset} setFeaturedAsset={setFeaturedAsset} />
          )}

          {/* Product info */}
          <div className='mt-10 px-4 sm:px-0 sm:mt-16 lg:mt-0'>
            <div className=''>
              <h3 className='sr-only'>Description</h3>

              <div
                className='text-base text-gray-700'
                dangerouslySetInnerHTML={{
                  __html: product.description,
                }}
              />
            </div>
            {variants.map((v) => (
              <VariantOption
                key={v.getCode()}
                value={selectedVariants[v.getCode()]}
                options={v.getOptions()}
                handleSelect={(op) => setSelectedVariants(v.getCode(), op.value)}
                label={v.getName()}
              />
            ))}
            <div className='mt-10 flex flex-col sm:flex-row sm:items-center'>
              <p className='text-3xl text-gray-900 mr-4'>
                <Price priceWithTax={selectedVariant?.priceWithTax} currencyCode={selectedVariant?.currencyCode}></Price>
              </p>
              <div className='flex sm:flex-col1 align-baseline'>
                <button
                  onClick={() => selectedVariantId && addItemToOrder(selectedVariantId, 1)}
                  className={`max-w-xs flex-1 ${
                    isLoadingOrder
                      ? 'bg-gray-400'
                      : qtyInCart === 0
                      ? 'bg-primary-600 hover:bg-primary-700'
                      : 'bg-green-600 active:bg-green-700 hover:bg-green-700'
                  }
                                     transition-colors border border-transparent rounded-md py-3 px-8 flex items-center
                                      justify-center text-base font-medium text-white focus:outline-none
                                      focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary-500 sm:w-full`}
                  disabled={isLoadingOrder || !selectedVariantId}
                >
                  {qtyInCart ? (
                    <span className='flex items-center'>
                      <CheckIcon className='w-5 h-5 mr-1' /> {qtyInCart} in cart
                    </span>
                  ) : (
                    'Add to cart'
                  )}
                </button>

                {/* <button
                  type='button'
                  className='ml-4 py-3 px-3 rounded-md flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500'
                >
                  <HeartIcon className='h-6 w-6 flex-shrink-0' aria-hidden='true' />
                  <span className='sr-only'>Add to favorites</span>
                </button> */}
              </div>
            </div>

            <div className='mt-2 flex items-center space-x-2'>
              <span className='text-gray-500'>{selectedVariant?.sku}</span>
              <StockLevelLabel stockLevel={selectedVariant?.stockLevel} />
            </div>
            {addItemToOrderError && (
              <div className='mt-4'>
                <Alert message={addItemToOrderError} />
              </div>
            )}

            <section className='mt-12 pt-12 border-t text-xs'>
              <h3 className='text-gray-600 font-bold mb-2'>Shipping & Returns</h3>
              <div className='text-gray-500 space-y-1'>
                <p>Standard shipping: 3 - 5 working days. Express shipping: 1 - 3 working days.</p>
                <p>Shipping costs depend on delivery address and will be calculated during checkout.</p>
                <p>
                  Returns are subject to terms. Please see the <span className='underline'>returns page</span> for further
                  information.
                </p>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div className='mt-24'>
        <TopReviews></TopReviews>
      </div>
    </div>
  );
}

function getAddItemToOrderError(error?: ErrorResult): string | undefined {
  if (!error || !error.errorCode) {
    return undefined;
  }
  switch (error.errorCode) {
    case ErrorCode.OrderModificationError:
    case ErrorCode.OrderLimitError:
    case ErrorCode.NegativeQuantityError:
    case ErrorCode.InsufficientStockError:
      return error.message;
  }
}
