import { useCreateReviewProductMutation, useProductReviewsLazyQuery } from '~/generated/graphql';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

export const useReview = () => {
  const { slug } = useParams();
  const [fetchReviews, reviewsObj] = useProductReviewsLazyQuery();
  const [createReview, createReviewObj] = useCreateReviewProductMutation();

  useEffect(() => {
    if (slug) {
      fetchReviews({ variables: { slug } });
    }
  }, []);

  return {
    reviews: reviewsObj.data?.product?.reviews.items || [],
    totalReviews: reviewsObj.data?.product?.reviews.totalItems || 0,
    fetchReviews,
    canReview: reviewsObj.data?.product?.canReview,
    called: reviewsObj.called,
    isLoading: reviewsObj.loading || createReviewObj.loading,
    slug,
    createReview,
  };
};
