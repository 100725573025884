import { generatePath, Link } from 'react-router-dom';
import { PAGES } from '~/constants/pages';
import { useReview } from '~/hooks/useReview';
import { navigate } from '~/store/navigator';
import { z } from 'zod';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { RefObject, useEffect } from 'react';
import { Input, Button, HighlightedButton } from '~/components/common';
import { useProduct } from '../../hooks/useProduct';
import { Price } from '../../components/products/Price';
import { Rating, Switch, Typography } from '@mui/material';
import { error } from 'console';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
// import { ProductCard } from '~/components/products/ProductCard';

export const reviewScheme = z.object({
  title: z.string().min(1, { message: 'Title is required' }),
  productId: z.string().min(1, { message: 'Title is required' }),
  description: z.string(),
  stars: z
    .number()
    .min(1, { message: 'Rate is required' })
    .max(5)
    .refine((v) => Number(v)),
  customerNameIsPublic: z.boolean(),
});

type ReviewType = z.infer<typeof reviewScheme>;

const ProductCard = ({ product }: { product: any }) => {
  return (
    <div className='flex flex-col'>
      <img
        className='rounded-xl flex-grow object-cover aspect-[7/8]'
        alt=''
        src={product.featuredAsset?.preview + '?w=60&h=60'}
        height={60}
        width={60}
      />
      <div className='h-2' />
      <div className='text-sm text-gray-700'>{product.name}</div>
    </div>
  );
};

export const ProductReviewPage = () => {
  const { canReview, called, slug, createReview, isLoading } = useReview();
  const { product } = useProduct();

  const navigateToProduct = () => navigate(slug ? generatePath(PAGES.product.href, { slug: slug }) : PAGES.home.href);

  if (called && !canReview && !isLoading) {
    navigateToProduct();
  }

  const formHook = useForm<ReviewType>({
    resolver: zodResolver(reviewScheme),
    defaultValues: {
      title: '',
      description: '',
      stars: 0,
      customerNameIsPublic: true,
      productId: product?.id,
    },
  });
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = formHook;
  console.log('form error', errors);
  const onSubmit: SubmitHandler<ReviewType> = (data) => {
    createReview({ variables: { input: { ...data } } }).then((d) => {
      if (!d.errors) {
        navigateToProduct();
      }
    });
  };

  return (
    <div className='max-w-xl mx-auto px-4'>
      <h2 className='text-lg font-medium text-gray-900'>Create review</h2>
      {product && <ProductCard product={product} />}
      <form id='editAddressForm' onSubmit={handleSubmit(onSubmit)}>
        <div className='grid grid-cols-1 gap-y-2 my-8'>
          <Typography component='legend'>Rate</Typography>
          <Controller
            name='stars'
            control={control}
            render={({ field: { value, onChange } }) => (
              <Rating name='stars' value={value} onChange={(e, newVal) => onChange(Number(newVal))} />
            )}
          />
          <Typography component='legend'>Show your name?</Typography>
          <Controller name='customerNameIsPublic' control={control} render={({ field }) => <Switch {...field} />} />

          <input type='hidden' {...register('productId')} />
          <Input label='Title' name='title' formHook={formHook} />
          <Input label='Description' name='description' formHook={formHook} multi />
          <div className='flex gap-x-4'>
            <HighlightedButton type='submit' isSubmitting={isLoading}>
              <CheckIcon className='w-4 h-4' /> Save
            </HighlightedButton>

            <Button type='reset' onClick={navigateToProduct}>
              <XMarkIcon className='w-4 h-4' /> Cancel
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
