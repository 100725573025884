import { useState, useCallback } from 'react';
import { z } from 'zod';
import { useForm, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Input, HighlightedButton, Button } from '~/components/common';
import { PencilIcon, CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';

export const customerScheme = z.object({
  emailAddress: z.string().min(1, { message: 'Email is required' }).email('Must be a valid email').nonempty('Email is required'),
  firstName: z.string().min(1, { message: 'First name is required' }).nonempty('First name is required'),
  lastName: z.string().min(1, { message: 'Last name is required' }).nonempty('Last name is required'),
});

type CustomerType = z.infer<typeof customerScheme>;

export function CustomerForOrder({
  customer,
  onSave,
  isEditable,
  isLoading,
  error,
}: {
  customer?: CustomerType | null;
  onSave?: (data: CustomerType) => void;
  isEditable?: boolean;
  isLoading?: boolean;
  error?: any;
}) {
  const [isEditing, setIsEditing] = useState(isEditable === true ? false : true);
  const formHook = useForm<CustomerType>({
    resolver: zodResolver(customerScheme),
    defaultValues: {
      emailAddress: customer?.emailAddress || undefined,
      firstName: customer?.firstName || undefined,
      lastName: customer?.lastName || undefined,
    },
  });
  const { handleSubmit } = formHook;

  const onSubmit: SubmitHandler<CustomerType> = useCallback(
    async (data) => {
      console.log('data', data);
      if (onSave) {
        onSave(data);
      }
      if (isEditable) {
        setIsEditing(false);
      }
    },
    [customer],
  );

  const startEditing = (e: any) => {
    e.preventDefault();
    setIsEditing(true);
  };

  const stopEditing = (e: any) => {
    e.preventDefault();
    setIsEditing(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4'>
      <div className='sm:col-span-2'>
        {isEditing && <Input label='Email address' name='emailAddress' required autoComplete='email' formHook={formHook} />}
        {!isEditing && (
          <>
            <label htmlFor='emailAddress' className='block text-sm font-medium text-gray-700'>
              Full name
            </label>
            <div className='mt-1'>{customer?.emailAddress}</div>
          </>
        )}
        {error?.errorCode === 'EMAIL_ADDRESS_CONFLICT_ERROR' && (
          <p className='mt-2 text-sm text-red-600' id='email-error'>
            {error.message}
          </p>
        )}
      </div>

      <div>
        {isEditing && <Input label='First name' name='firstName' required autoComplete='given-name' formHook={formHook} />}
        {!isEditing && (
          <>
            <label htmlFor='firstName' className='block text-sm font-medium text-gray-700'>
              City
            </label>
            <div className='mt-1'>{customer?.firstName ?? ''}</div>
          </>
        )}
      </div>

      <div>
        {isEditing && <Input label='Last name' name='lastName' required autoComplete='family-name' formHook={formHook} />}
        {!isEditing && (
          <>
            <label htmlFor='lastName' className='block text-sm font-medium text-gray-700'>
              City
            </label>
            <div className='mt-1'>{customer?.lastName ?? ''}</div>
          </>
        )}
      </div>

      {isEditing ? (
        <div className='flex gap-x-4'>
          <HighlightedButton type='submit' isSubmitting={isLoading}>
            <CheckIcon className='w-4 h-4' /> Save
          </HighlightedButton>

          {isEditable && (
            <Button onClick={stopEditing}>
              <XMarkIcon className='w-4 h-4' /> Cancel
            </Button>
          )}
        </div>
      ) : (
        <HighlightedButton onClick={startEditing} className='flex gap-x-4'>
          <PencilIcon className='w-4 h-4' /> Edit
        </HighlightedButton>
      )}
    </form>
  );
}
