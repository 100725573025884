import { useSearchParams } from 'react-router-dom';
import { PAGINATION_LIMIT, paginationParams } from '../constants/pagination';
export const usePagination = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = Number(searchParams.get(paginationParams.page) ?? 1);
  const limit = Number(searchParams.get(paginationParams.limit) ?? PAGINATION_LIMIT);

  const setPage = (value: number) => {
    if (!value || value === page) {
      return;
    }
    searchParams.set(paginationParams.page, value.toString());
    setSearchParams(searchParams);
  };

  const setLimit = (value?: string) => {
    if (!value || Number(value) === limit) {
      return;
    }
    searchParams.set(paginationParams.limit, value);
    setSearchParams(searchParams);
  };

  return { page, limit, setPage, setLimit, skip: (page - 1) * limit };
};
