import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { isAuthorizedSelector, tokenSelector, appActions } from '../store/app/reducer';
import { navigate } from '../store/navigator';
import { PAGES } from '../constants/pages';
import { useLogin } from '../hooks/useLogin';

export default function AuthComponent({ children }: any) {
  const isAuthorized = useSelector(isAuthorizedSelector);
  const token = useSelector(tokenSelector);
  const location = useLocation();
  const dispatch = useDispatch();
  const { isLoading } = useLogin();
  console.log('moj: Auth component', token, isAuthorized);

  if (!isAuthorized && !isLoading) {
    const { pathname, search } = location;
    const path = pathname + search;
    dispatch(appActions.setRedirectUrl(path));
    navigate(`${PAGES.login.href}?redirect=${path}`, true);
    // return <LoginPage />;
  }
  return <>{children}</>;
}
