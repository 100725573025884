import React, { SelectHTMLAttributes } from 'react';
import FormElement from './FormElement';

export type SelectProps = {
  placeholder?: string;
  noPlaceholder?: boolean;
  label?: string;
  name: string;
  formHook: any;
} & SelectHTMLAttributes<HTMLSelectElement>;

export const Select = ({
  name,
  label,
  required,
  defaultValue,
  placeholder = 'Select...',
  noPlaceholder = false,
  formHook,
  children,
}: SelectProps) => {
  const { register } = formHook;
  return (
    <FormElement name={name} label={label} required={required}>
      <select
        defaultValue={defaultValue}
        className='block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm'
        {...register(name)}
      >
        {!noPlaceholder && <option value=''>{placeholder}</option>}
        {children}
      </select>
    </FormElement>
  );
};
