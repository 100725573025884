import { useRef, useEffect, useMemo } from 'react';
import Modal from '~/components/modal/Modal';
import useToggleState from '~/hooks/useToggleState';
import { CustomerAddressForm } from '~/components/account/CustomerAddressForm';
import {
  useAvailableCountriesQuery,
  useUpdateCustomerAddressMutationMutation,
  useActiveCustomerAddressesQuery,
} from '~/generated/graphql';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingComponent } from '~/components/common';

export default function EditAddress() {
  const { data, loading } = useAvailableCountriesQuery();
  const [addAddress, addedAddress] = useUpdateCustomerAddressMutationMutation();
  const availableCountries = data?.availableCountries || [];
  const navigate = useNavigate();
  const { state, close } = useToggleState(true);
  const { addressId } = useParams();
  const { data: activeCustomerData, loading: addressLoading } = useActiveCustomerAddressesQuery({ fetchPolicy: 'network-only' });
  const activeCustomerAddresses = activeCustomerData?.activeCustomer;
  const address = useMemo(
    () => activeCustomerAddresses?.addresses?.find((add) => add.id === addressId),
    [addressId, activeCustomerAddresses],
  );

  useEffect(() => {
    if (addedAddress.data?.updateCustomerAddress.__typename === 'Address') {
      close();
    }
  }, [addedAddress]);

  const afterClose = () => {
    navigate(-1);
  };

  if (!address || addressLoading || loading) {
    return <LoadingComponent />;
  }
  return (
    <div>
      <Modal isOpen={state} close={close} afterClose={afterClose}>
        <Modal.Title>New address</Modal.Title>
        <Modal.Body>
          <CustomerAddressForm
            address={address as any}
            availableCountries={availableCountries}
            submit={close}
          ></CustomerAddressForm>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button type='button' onClick={close}>
            Cancel
          </Button>
          <HighlightedButton isSubmitting={addedAddress.loading} onClick={submitForm}>
            Save
          </HighlightedButton> */}
        </Modal.Footer>
      </Modal>
    </div>
  );
}
