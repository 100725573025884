import { PhotoIcon } from '@heroicons/react/24/solid';

export function CatchBoundary() {
  return (
    <div className='max-w-6xl mx-auto px-4'>
      <h2 className='text-3xl sm:text-5xl font-light tracking-tight text-gray-900 my-8'>Product not found!</h2>
      <div className='lg:grid lg:grid-cols-2 lg:gap-x-8 lg:items-start mt-4 md:mt-12'>
        {/* Image gallery */}
        <div className='w-full max-w-2xl mx-auto sm:block lg:max-w-none'>
          <span className='rounded-md overflow-hidden'>
            <div className='w-full h-96 bg-slate-200 rounded-lg flex content-center justify-center'>
              <PhotoIcon className='w-48 text-white'></PhotoIcon>
            </div>
          </span>
        </div>

        {/* Product info */}
        <div className='mt-10 px-4 sm:px-0 sm:mt-16 lg:mt-0'>
          <div className=''>We couldn't find any product at that address!</div>
          <div className='flex-1 space-y-3 py-1'>
            <div className='h-2 bg-slate-200 rounded'></div>
            <div className='space-y-3'>
              <div className='grid grid-cols-3 gap-4'>
                <div className='h-2 bg-slate-200 rounded col-span-2'></div>
                <div className='h-2 bg-slate-200 rounded col-span-1'></div>
              </div>
              <div className='h-2 bg-slate-200 rounded'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
