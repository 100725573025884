import {
  Channel,
  Collection,
  Customer,
  Order,
  useActiveChannelQuery,
  useActiveCustomerQuery,
  useActiveOrderQuery,
  useCollectionsQuery,
} from '../generated/graphql';

export type RootLoaderData = {
  // activeCustomer: Customer | undefined | any;
  activeChannel: Channel | undefined | any;
  collections: Collection[] | undefined | any;
  menu: Collection[] | undefined | any;
  // activeOrder: Order | undefined | any;
  loading: boolean;
};

export function useRootLoader(): RootLoaderData {
  const {
    data: collections,
    error: colectionError,
    loading: collectionsLoading,
  } = useCollectionsQuery({ fetchPolicy: 'network-only' });
  const {
    data: activeChannel,
    error: activeChannelError,
    loading: activeChannelLoading,
  } = useActiveChannelQuery({ fetchPolicy: 'network-only' });
  // const { data: activeCustomer, error: activeCustomerError, loading: activeCustomerlLoading } = useActiveCustomerQuery();
  // const { data: activeOrder, error: activeOrderError, loading: activeOrderLoading } = useActiveOrderQuery();
  return {
    loading: collectionsLoading || activeChannelLoading,
    // activeCustomer: activeCustomer?.activeCustomer,
    activeChannel: activeChannel?.activeChannel,
    // activeOrder: activeOrder?.activeOrder,
    menu: collections?.collections.items.filter((collection) => collection.parent?.name === '__root_collection__'),
    collections: collections?.collections.items,
  };
}
