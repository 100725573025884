import { OrderState, orderInitState, orderReducer } from './order/reducer';
import { combineReducers, CombinedState, createAction, AnyAction, createReducer } from '@reduxjs/toolkit';
import { AppState, appInitState, appReducer } from './app/reducer';
import { CustomerState, customerInitState, customerReducer } from './customer/reducer';
import { resetAllStateAction } from './actions';

export type State = CombinedState<{
  app: AppState;
  order: OrderState;
  customer: CustomerState;
  // user: UserState;
  // cart: CartState;
}>;

export const initialState: State = {
  app: appInitState,
  order: orderInitState,
  customer: customerInitState,
  // user: userState,
  // cart: cartState,
};

const rootReducer = combineReducers<State, AnyAction>({
  app: appReducer,
  order: orderReducer,
  customer: customerReducer,
  //   user: userReducer,
  //   cart: cartReducer,
});

export default function rootReducers(state: State = initialState, action: AnyAction) {
  if (action.type === resetAllStateAction.toString()) {
    // return rootReducer(initialState, action);
  }
  return rootReducer(state, action);
}
