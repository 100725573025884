import { createRef } from 'react';
import { NavigateFunction } from 'react-router-dom';

export const isReadyRef: any = createRef<boolean>();

isReadyRef.current = false;

export const navigationRef: any = createRef<{ navigate: NavigateFunction | undefined }>();

navigationRef.current = { navigate: undefined };

export function navigate(path: string, replace = false) {
  console.log('called', navigationRef.current);
  if (isReadyRef.current && navigationRef.current?.navigate) {
    navigationRef.current.navigate(path, { replace });
  }
}

export function goBack() {
  if (isReadyRef.current && navigationRef.current?.navigate) {
    // Perform navigation if the app has mounted
    navigationRef.current.navigate(-1);
  }
}

export function setNavigation(navigator: NavigateFunction) {
  if (navigator) {
    navigationRef.current.navigate = navigator;
    isReadyRef.current = true;
  }
}

export function isNavigatorReady() {
  return isReadyRef.current && !!navigationRef.current?.navigate;
}
