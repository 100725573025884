export const PAGES = {
  home: {
    id: 'home',
    href: '/',
  },
  product: {
    id: 'product',
    href: '/products/:slug',
  },
  productReviews: {
    id: 'productReviews',
    href: '/products/:slug/reviews',
  },
  addProductReview: {
    id: 'addProductReview',
    href: '/products/:slug/reviews/new',
  },
  collections: {
    id: 'collections',
    href: '/collections/:slug',
  },
  search: {
    id: 'search',
    href: '/search',
  },
  login: {
    id: 'login',
    href: '/sign-in',
  },
  forgotPassword: {
    id: 'forgotPassword',
    href: '/forgot-password',
  },
  passwordReset: {
    id: 'passwordReset',
    href: '/password-reset',
  },
  register: {
    id: 'register',
    href: '/sign-up',
  },
  registerSuccess: {
    id: 'registerSuccess',
    href: '/sign-up/success',
  },
  profile: {
    id: 'profile',
    href: '/account',
    withAuth: true,
  },
  verify: {
    id: 'verify',
    href: '/verify',
  },
  newAddress: {
    id: 'newAddress',
    href: '/account/new-address',
    withAuth: true,
  },
  addressInfo: {
    id: 'address',
    href: '/account/addresses/:addressId',
    withAuth: true,
  },
  checkout: {
    id: 'checkout',
    href: '/checkout/*',
  },
  payment: {
    id: 'payment',
    href: '/checkout/payment',
  },
  confirmation: {
    id: 'confirmation',
    href: '/confirmation/:orderCode',
  },
  policy: {
    id: 'policy',
    href: '/policy',
  },
  usage: {
    id: 'usage',
    href: '/usage',
  },
  error: {
    id: 'error',
    href: '/error',
  },
  any: {
    id: 'any',
    href: '*',
  },
};
