import { ScrollableContainer } from '~/components/products/ScrollableContainer';
import { Asset, Product } from '~/generated/graphql';
export const ImageGallery = ({
  product,
  featuredAsset,
  setFeaturedAsset,
}: {
  product: Product;
  featuredAsset: Asset;
  setFeaturedAsset: (asset: Asset) => void;
}) => {
  return (
    <div className='w-full max-w-2xl mx-auto sm:block lg:max-w-none'>
      <span className='rounded-md overflow-hidden'>
        <div className='w-full h-full object-center object-cover rounded-lg'>
          <img
            src={(featuredAsset?.preview || product.featuredAsset?.preview) + '?w=800'}
            alt={product.name}
            className='w-full h-full object-center object-cover rounded-lg'
          />
        </div>
      </span>

      {product.assets.length > 1 && (
        <ScrollableContainer>
          {product.assets.map((asset) => (
            <div
              className={`basis-1/3 md:basis-1/4 flex-shrink-0 select-none touch-pan-x rounded-lg ${
                featuredAsset?.id == asset.id ? 'outline outline-2 outline-primary outline-offset-[-2px]' : ''
              }`}
              onClick={() => {
                setFeaturedAsset(asset);
              }}
            >
              <img
                draggable='false'
                className='rounded-lg select-none h-24 w-full object-cover'
                src={asset.preview + '?preset=full' /* not ideal, but technically prevents loading 2 seperate images */}
              />
            </div>
          ))}
        </ScrollableContainer>
      )}
    </div>
  );
};
