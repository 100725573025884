import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import createStore from './store';
import { client } from './apolloClient';
import Pages from './pages';

const { store, persistor } = createStore();

export { store };

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ApolloProvider client={client}>
            <Pages />
          </ApolloProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
