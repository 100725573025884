import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSearchFacetValuesLazyQuery, useSearchLazyQuery, SearchFacetValuesQuery, SearchQuery } from '~/generated/graphql';

export interface UseFacetFilter {
  result: SearchQuery['search'] | undefined;
  resultWithoutFacetValueFilters: SearchFacetValuesQuery['search'] | undefined;
  facetValueIds: string[];
  isLoading: boolean;
  term: string | null;
}

export const usefacetFilter = (): UseFacetFilter => {
  const [search, { data: result, loading: l1, error: e1, called: c1 }] = useSearchLazyQuery();
  const [searchFacetValues, { data: resultWithoutFacetValueFilters, loading: l2, error: e2, called: c2 }] =
    useSearchFacetValuesLazyQuery();

  const params = useParams();

  const [searchParams] = useSearchParams();
  const facetValueIds = searchParams.getAll('fvid');
  const term = searchParams.get('q');

  useEffect(() => {
    search({
      variables: {
        input: {
          groupByProduct: true,
          term,
          facetValueIds,
          collectionSlug: params.slug,
        },
      },
    });
    if (facetValueIds.length) {
      searchFacetValues({ variables: { input: { groupByProduct: true, term, collectionSlug: params.slug } } });
    }
  }, [searchParams, params]);

  const resultWithout = facetValueIds.length ? resultWithoutFacetValueFilters?.search : result?.search;

  return {
    term,
    isLoading: l1 || l2,
    result: result?.search,
    resultWithoutFacetValueFilters: resultWithout,
    facetValueIds: facetValueIds || [],
  };
};
