import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { arSD, enUS } from '@mui/material/locale';

const themeConfig = {
  palette: {
    primary: {
      main: '#427085',
    },
    secondary: {
      main: '#ecebe7',
    },
    error: {
      main: red.A400,
    },
  },
};

// A custom theme for this app
export const themeAr = createTheme(
  {
    direction: 'rtl',
    ...themeConfig,
    typography: {
      allVariants: {
        fontFamily: 'Noto Kufi Arabic, libre franklin, ui-sans-serif, sans-serif',
      },
    },
  },
  arSD,
);

export const themeEn = createTheme(
  {
    ...themeConfig,
    typography: {
      allVariants: {
        fontFamily: 'libre franklin, ui-sans-serif, sans-serif, Noto Kufi Arabic',
      },
    },
  },
  enUS,
);
