import clsx from 'clsx';
import React from 'react';
import FormElement from './FormElement';

type InputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'placeholder'> & {
  label?: string;
  name: string;
  required?: boolean;
  error?: string;
  formHook: any;
  multi?: boolean;
};

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ name, label, required, formHook, multi, ...props }, ref) => {
    const { register, formState } = formHook;
    const error = formState.errors[name]?.message || '';
    const tag = !multi ? (
      <input
        className={clsx(
          'block w-full py-2 px-4 shadow-sm border bg-white rounded-md text-base sm:text-sm text-gray-900 border-gray-300 placeholder-gray-500',
          'focus:ring-primary-500 focus:border-primary-500 focus:outline-none focus:ring-2 focus:placeholder-gray-400',
          {
            'border-rose-500 focus:border-rose-500': error,
          },
          props.className,
        )}
        {...register(name)}
      />
    ) : (
      <textarea
        className={clsx(
          'block w-full py-2 px-4 shadow-sm border bg-white rounded-md text-base sm:text-sm text-gray-900 border-gray-300 placeholder-gray-500',
          'focus:ring-primary-500 focus:border-primary-500 focus:outline-none focus:ring-2 focus:placeholder-gray-400',
          {
            'border-rose-500 focus:border-rose-500': error,
          },
          props.className,
        )}
        {...register(name)}
      />
    );
    return (
      <FormElement name={name} label={label} required={required} error={error}>
        {tag}
      </FormElement>
    );
  },
);
