import { Pagination } from '@mui/material';
import { FunctionComponent, ChangeEvent } from 'react';
import { PAGINATION_LIMIT } from '../../constants/pagination';
import { ArrowPathIcon } from '@heroicons/react/24/solid';
import { usePagination } from '../../hooks/usePagination';

export interface PaginationComponentProps {
  total: number;
  pageItems: number;
  isLoading?: boolean;
}

export const PaginationComponent: FunctionComponent<PaginationComponentProps> = ({ isLoading, total, pageItems }) => {
  const { page, limit, setPage, setLimit } = usePagination();
  const PAGINATION_OPTIONS = [10, PAGINATION_LIMIT, 50];

  const onChange = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleSwitchPaginationOption = (e: any) => {
    const value = e.target.value || PAGINATION_LIMIT;
    setLimit(value);
  };

  const showingOrdersFrom = (page - 1) * limit + 1;
  const showingOrdersTo = showingOrdersFrom + pageItems - 1;

  return (
    <div className='flex flex-row justify-between items-center gap-4'>
      <span className='self-start text-gray-500 text-sm ml-4 lg:ml-6 mt-2'>
        Showing {showingOrdersFrom} to {showingOrdersTo} of {total}
      </span>
      <div className='flex flex-col md:flex-row justify-center items-end md:items-center gap-4 lg:gap-6'>
        <span className='flex gap-4 items-center'>
          {isLoading && <ArrowPathIcon className='animate-spin h-6 w-6 text-gray-500' />}
          <select
            className='block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm'
            defaultValue={PAGINATION_LIMIT}
            onChange={handleSwitchPaginationOption}
          >
            {PAGINATION_OPTIONS.map((x) => (
              <option key={x} value={x}>
                {x} per Page
              </option>
            ))}
          </select>
        </span>

        <div className='flex' role='group'>
          <Pagination count={total} variant='outlined' shape='rounded' page={page} onChange={onChange} />
        </div>
      </div>
    </div>
  );
};
