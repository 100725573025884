import { Tab, TabProps } from './Tab';
import { useSearchParams } from 'react-router-dom';

export function TabsContainer({ tabs }: { tabs: TabProps[] }) {
  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get('activeTab') || '';
  const Component = tabs.find((tab) => tab.to === activeTab)?.Component;

  return (
    <>
      <div className='border-b border-gray-200 mt-4'>
        <ul className='gap-x-4 grid grid-cols-2 sm:grid-0 sm:flex sm:flex-wrap -mb-px text-sm font-medium text-center text-gray-500'>
          {tabs.map((props) => (
            <Tab Icon={props.Icon} text={props.text} to={props.to} key={props.text} />
          ))}
        </ul>
      </div>
      <Component />
    </>
  );
}
