import { useDispatch, useSelector } from 'react-redux';
import { useActiveCustomerDetailsLazyQuery } from '~/generated/graphql';
import { customerActions, Customer } from '~/store/customer/reducer';
import { activeCustomerSelector } from '~/store/customer/selectors';

export const useActiveCustomer = () => {
  const dispatch = useDispatch();
  const activeCustomer: Customer = useSelector(activeCustomerSelector);
  const [fetchActiveCustomerFn, fetchActiveCustomerObj] = useActiveCustomerDetailsLazyQuery();

  const fetchActiveCustomer = async () => {
    const d = await fetchActiveCustomerFn({ fetchPolicy: 'network-only' });
    console.log('done fetch active user');
    dispatch(customerActions.updateActiveCustomer(d.data?.activeCustomer));
    return d.data?.activeCustomer;
  };

  return { activeCustomer, isLoading: fetchActiveCustomerObj.loading, error: fetchActiveCustomerObj.error, fetchActiveCustomer };
};
