import { CartContents } from '~/components/cart/CartContents';
import { CartTotals } from '~/components/cart/CartTotals';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import { OrderDetailFragment } from '~/generated/graphql';
import { usePayment } from '../../hooks/usePayment';
import { useParams } from 'react-router-dom';

export default function CheckoutConfirmation() {
  const { order, getOrderByCode, getOrderByCodeObj } = usePayment();
  const { orderCode } = useParams();
  const [retries, setRetries] = useState(1);
  const error = getOrderByCodeObj.error;
  const processingPayment = getOrderByCodeObj.loading;
  console.log(error, retries);

  const orderNotFound = !order && !error;
  const orderErrored = !order && error;
  const maxRetries = 5;
  const retriesExhausted = retries >= maxRetries;
  const retryTimeout = 5000;

  const retry = () => {
    if (!window) return;
    setRetries(retries + 1);
    window.setTimeout(() => {
      if (retries > maxRetries) return;
      getOrderByCode(orderCode || '');
    }, retryTimeout);
  };

  useEffect(() => {
    if (orderErrored) {
      retry();
    }
  }, [orderErrored]);

  useEffect(() => {
    if (!processingPayment && orderErrored && retries <= maxRetries && retries > 1) {
      retry();
    }
  }, [processingPayment]);

  useEffect(() => {
    getOrderByCode(orderCode || '');
  }, []);

  if (orderNotFound) {
    return (
      <div className='flex flex-col items-center divide-gray-200 divide-y'>
        <h2 className='text-3xl sm:text-5xl font-light tracking-tight text-gray-900 my-8'>No matching order found!</h2>
      </div>
    );
  }

  if (orderErrored && retriesExhausted) {
    return (
      <div className='flex flex-col items-center divide-gray-200 divide-y'>
        <h2 className='text-3xl flex items-center space-x-2 sm:text-5xl font-light tracking-tight text-gray-900 my-8'>
          <XCircleIcon className='text-red-600 w-8 h-8 sm:w-12 sm:h-12'></XCircleIcon>
          <span>An error occured!</span>
        </h2>
        <p className='text-lg text-gray-700'>
          Unfortunately your payment could not be processed or this confirmation link has expired.
        </p>
      </div>
    );
  }

  if (orderErrored) {
    return (
      <div className='flex flex-col items-center divide-gray-200 divide-y'>
        <h2 className='text-3xl flex items-center space-x-2 sm:text-5xl font-light tracking-tight text-gray-900 my-8'>
          Please wait while we process your order...
        </h2>
      </div>
    );
  }

  return (
    <div className='flex flex-col items-center divide-gray-200 divide-y'>
      <h2 className='text-3xl flex items-center space-x-2 sm:text-5xl font-light tracking-tight text-gray-900 my-8'>
        <CheckCircleIcon className='text-green-600 w-8 h-8 sm:w-12 sm:h-12'></CheckCircleIcon>
        <span>Order Summary</span>
      </h2>
      <p className='text-lg text-gray-700'>
        Your order <span className='font-bold'>{order!.code}</span> has been received!
      </p>
      {order!.active && (
        <div className='rounded-md bg-blue-50 p-4 my-8'>
          <div className='flex'>
            <div className='flex-shrink-0'>
              <InformationCircleIcon className='h-5 w-5 text-blue-400' aria-hidden='true' />
            </div>
            <div className='ml-3 flex-1 md:flex md:justify-between'>
              <p className='text-sm text-blue-700'>
                {' '}
                Note: your payment is still being processed. You will receive an email confirmation once the payment has
                completed.
              </p>
            </div>
          </div>
        </div>
      )}
      <div className='mt-12'>
        <div className='mb-6'>
          <CartContents orderLines={order!.lines} currencyCode={order!.currencyCode} editable={false} />
        </div>
        <CartTotals order={order as OrderDetailFragment}></CartTotals>
      </div>
    </div>
  );
}
