/* eslint-disable camelcase */
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { FormEvent } from 'react';
import { CreditCardIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { usePayment } from '../../../hooks/usePayment';

export const CheckoutForm = ({ paymentMethodCode, orderCode }: { paymentMethodCode: string; orderCode: string }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { processPayment, error: paymentError } = usePayment();

  const handleSubmit = async (event: FormEvent) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    console.log('nothing');

    // await processPayment(paymentMethodCode, '', true);

    const result = await stripe.confirmPayment({
      // `Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: location.origin + `/confirmation/${orderCode}`,
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <>
      {paymentError && (
        <div className='rounded-md bg-red-50 p-4 mb-8'>
          <div className='flex'>
            <div className='flex-shrink-0'>
              <XCircleIcon className='h-5 w-5 text-red-400' aria-hidden='true' />
            </div>
            <div className='ml-3'>
              <h3 className='text-sm font-medium text-red-800'>There was an error processing the payment</h3>
              <div className='mt-2 text-sm text-red-700'>{paymentError}</div>
            </div>
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <button
          disabled={!stripe}
          className='flex w-full px-6 bg-primary-600 hover:bg-primary-700 items-center justify-center space-x-2 py-3 my-4 border border-transparent text-base font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'
        >
          <CreditCardIcon className='w-5 h-5'></CreditCardIcon>
          <span>Pay with Stripe</span>
        </button>
      </form>
    </>
  );
};
