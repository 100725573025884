import { PayloadAction, createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { State } from '~/store/reducers';
import { activeCustomerSelector } from '../customer/selectors';
import { resetAllStateAction } from '../actions';

export enum AppLocale {
  AR = 'ar',
  EN = 'en',
}

export interface TokenDto {
  token: string;
  userId: string;
  refreshToken?: string;
  active: boolean;
}

export interface AppState {
  isAppReady: boolean;
  loading: number;
  tokenInfo: TokenDto;
  redirectUrl: string;
  locale: AppLocale;
  // alerts: AlertProps[];
  activeRoute: string;
}

const namespace = 'app';
export const appActions = {
  initializeTheApp: createAction(`${namespace}/initializeTheApp`),
  setAppReady: createAction<boolean>(`${namespace}/setAppReady`),
  showLoading: createAction(`${namespace}/showLoading`),
  hideLoading: createAction(`${namespace}/hideLoading`),
  setToken: createAction<TokenDto>(`${namespace}/setToken`),
  setRedirectUrl: createAction<string>(`${namespace}/setRedirectUrl`),
  switchLanguage: createAction<AppLocale>(`${namespace}/switchLanguage`),
  updateLocale: createAction<AppLocale>(`${namespace}/updateLocale`),
  // loginByToken: createAction(`${namespace}/loginByToken`),
  // loginByPassword: createAction<LoginDto>(`${namespace}/loginByPassword`),
  authorize: createAction(`${namespace}/authorize`),
  resetToken: createAction(`${namespace}/resetToken`),
  // showAlert: createAction<AlertProps>(`${namespace}/showAlert`),
  // hideAlert: createAction<string>(`${namespace}/hideAlert`),
  // resetPassword: createAction<string>(`${namespace}/resetPassword`),
  // logout: createAction(`${namespace}/logout`),
  toggleActiveRoute: createAction<string>(`${namespace}/toggleActiveRoute`),
  updateActiveRoute: createAction<string>(`${namespace}/updateActiveRoute`),
};

export const appInitState: AppState = {
  isAppReady: false,
  loading: 0,
  tokenInfo: {
    token: '',
    refreshToken: '',
    userId: '',
    active: false,
  },
  redirectUrl: '',
  locale: AppLocale.EN,
  // alerts: [],
  activeRoute: 'home',
};

export const appReducer = createReducer(appInitState, (builder) => {
  builder
    .addCase(resetAllStateAction, (state: AppState) => {
      state.loading = 0;
      state.tokenInfo = {
        token: '',
        refreshToken: '',
        userId: '',
        active: false,
      };
      state.redirectUrl = '';
      // state.alerts = [];
    })
    .addCase(appActions.setAppReady, (state: AppState, action: PayloadAction<boolean>) => {
      state.isAppReady = action.payload;
    })
    .addCase(appActions.updateActiveRoute, (state: AppState, action: PayloadAction<string>) => {
      state.activeRoute = action.payload;
    })
    .addCase(appActions.setToken, (state: AppState, action: PayloadAction<TokenDto>) => {
      state.tokenInfo.token = action.payload.token;
      state.tokenInfo.refreshToken = action.payload.refreshToken;
      state.tokenInfo.active = action.payload.active;
      state.tokenInfo.userId = action.payload.userId;
      // axios.defaults.headers.common['Authorization'] = `Bearer ${action.payload.token}`;
    })
    .addCase(appActions.resetToken, (state: AppState) => {
      state.tokenInfo.token = '';
      state.tokenInfo.refreshToken = '';
      state.tokenInfo.active = false;
      state.tokenInfo.userId = '';
      // axios.defaults.headers.common['Authorization'] = '';
    })
    .addCase(appActions.updateLocale, (state: AppState, action: PayloadAction<AppLocale>) => {
      state.locale = action.payload;
    })
    .addCase(appActions.showLoading, (state: AppState) => {
      state.loading += 1;
    })
    .addCase(appActions.hideLoading, (state: AppState) => {
      state.loading -= 1;
    })
    // .addCase(appActions.showAlert, (state: AppState, action: PayloadAction<AlertProps>) => {
    //   state.alerts.push(action.payload);
    // })
    // .addCase(appActions.hideAlert, (state: AppState, action: PayloadAction<string>) => {
    //   state.alerts = state.alerts.filter((alert) => alert.id !== action.payload);
    // })
    .addCase(appActions.setRedirectUrl, (state: AppState, action: PayloadAction<string>) => {
      state.redirectUrl = action.payload;
    });
});

export const appStateSelector = (state: State) => state?.app || appInitState;

export const isAppReadySelector = createSelector(appStateSelector, (state) => state.isAppReady);

export const isAuthorizedSelector = createSelector(appStateSelector, activeCustomerSelector, (state, activeUser) => {
  console.log(state, activeUser);
  return state.tokenInfo.token && state.tokenInfo.token.length > 0 && !!activeUser?.id;
});

export const tokenSelector = createSelector(appStateSelector, (state) => state.tokenInfo);

export const activeRouteSelector = createSelector(appStateSelector, (state) => state.activeRoute);

export const localeSelector = createSelector(appStateSelector, (state) => state.locale);

export const isLoadingSelector = createSelector(appStateSelector, (state) => state.loading > 0);

// export const alertsSelector = createSelector(appStateSelector, (state) => state.alerts);

export const redirectUrlSelector = createSelector(appStateSelector, (state) => state.redirectUrl);
