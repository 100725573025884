import { PayloadAction, createAction, createReducer } from '@reduxjs/toolkit';
import { ActiveCustomerQuery } from '~/generated/graphql';
import { resetAllStateAction } from '../actions';

// export type Customer = ActiveCustomerQuery['activeCustomer'];

export interface CustomerInfo {
  id: string;
  identifier: string;
  firstName?: string;
  lastName?: string;
  title?: string;
  phoneNumber?: string;
  emailAddress?: string;
}

export type Customer = CustomerInfo | ActiveCustomerQuery['activeCustomer'] | undefined;

export interface LogoutAction {
  logout: any;
}

export interface CustomerState {
  activeCustomer: Customer;
}

const namespace = 'order';

export const customerActions = {
  fetchActiveCustomer: createAction(`${namespace}/fetchActiveCustomer`),
  updateActiveCustomer: createAction<Customer>(`${namespace}/updateActiveCustomer`),
  resetActiveCustomer: createAction(`${namespace}/resetActiveCustomer`),
  logout: createAction<LogoutAction>(`${namespace}/logout`),
};

export const customerInitState: CustomerState = {
  activeCustomer: undefined,
};

export const customerReducer = createReducer(customerInitState, (builder) => {
  builder
    .addCase(resetAllStateAction, (state: CustomerState) => {
      state.activeCustomer = undefined;
    })
    .addCase(customerActions.resetActiveCustomer, (state: CustomerState) => {
      state.activeCustomer = undefined;
    })
    .addCase(customerActions.updateActiveCustomer, (state: CustomerState, action: PayloadAction<Customer>) => {
      console.log('set customer', action.payload);
      state.activeCustomer = { ...state.activeCustomer, ...action.payload } as Customer;
    });
});
